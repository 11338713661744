/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/self-closing-comp */
/* eslint-disable quotes */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-quotes */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { AiOutlineLogout } from 'react-icons/ai';
import { BiSearch } from 'react-icons/bi';
import { Link, Outlet } from 'react-router-dom';
import IndexStyles from './index.module.scss';
import logo from '../../Images/TIWAlogo.svg';
import Category from '../../Images/Category1.svg';
import star from '../../Images/Star.svg';
import user from '../../Images/User.svg';
import { logout } from '../features/auth';
import { useDispatch } from "react-redux"

export default function Index() {
    const dispatch = useDispatch()
    const [navValue, setNavValue] = useState("");
    useEffect(() => {
        const tabs = () => {
            setNavValue(window.location.pathname);
        };
        tabs();
    });

    const tabs = () => {
        setNavValue(window.location.pathname);
    };
    return (
      <React.Fragment>
        <div className={IndexStyles.holdAll}>
          <div className={IndexStyles.NavBar}>
            <div
              className={IndexStyles.NavBar2}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={logo}
                alt="logo"
                style={{
                  height: "5em",
                  marginBottom: "2em",
                }}
              />
            </div>
            <ul
              style={{
                paddingLeft: 0,
              }}
            >
              <li className={IndexStyles.list} onClick={tabs}>
                <Link
                  to="reservation"
                  className={
                    navValue === "/admin/reservation"
                      ? IndexStyles.active
                      : IndexStyles.listLink
                  }
                >
                  <img
                    src={Category}
                    alt="logo"
                    className={IndexStyles.iconSvg}
                  />
                  Reservation
                </Link>
              </li>
              <li className={IndexStyles.list} onClick={tabs}>
                <Link
                  to="tiwaphase2"
                  className={
                    navValue === "/admin/tiwaphase2"
                      ? IndexStyles.active
                      : IndexStyles.listLink
                  }
                >
                  <img
                    src={Category}
                    alt="logo"
                    className={IndexStyles.iconSvg}
                  />
                  Tiwa Phase 2
                </Link>
              </li>
              <li className={IndexStyles.list} onClick={tabs}>
                <Link
                  to="make-enquiry"
                  className={
                    navValue === "/admin/make-enquiry"
                      ? IndexStyles.active
                      : IndexStyles.listLink
                  }
                >
                  <img src={star} alt="logo" className={IndexStyles.iconSvg} />
                  Make Enquiry
                </Link>
              </li>
              <li className={IndexStyles.list} onClick={tabs}>
                <Link
                  to="contact"
                  className={
                    navValue === "/admin/contact"
                      ? IndexStyles.active
                      : IndexStyles.listLink
                  }
                >
                  <img src={user} alt="logo" className={IndexStyles.iconSvg} />
                  Inspection Booking
                </Link>
              </li>
              <li className={IndexStyles.list} onClick={tabs}>
                <Link
                  to="reservationhub"
                  className={
                    navValue === "/admin/reservationhub"
                      ? IndexStyles.active
                      : IndexStyles.listLink
                  }
                >
                  <img src={user} alt="logo" className={IndexStyles.iconSvg} />
                  Reservation Hub
                </Link>
              </li>
              <li
                className={IndexStyles.list}
                onClick={() => {
                  sessionStorage.setItem("Logged", false);
                  dispatch(logout());
                  tabs();
                }}
              >
                <Link
                  to="/"
                  className={
                    navValue === "/" ? IndexStyles.active : IndexStyles.listLink
                  }
                >
                  <AiOutlineLogout className={IndexStyles.iconSvg} />
                  Log out
                </Link>
              </li>
            </ul>
          </div>

          <div className={IndexStyles.holdHeader}>
            <div className={IndexStyles.holdFlex}>
              <div>
                <input
                  type="search"
                  placeholder="search"
                  style={{ outline: "none", border: "none" }}
                />
                <BiSearch />
              </div>
              <div className={IndexStyles.headerSec}>
                <div className={IndexStyles.headerNoti}>
                  <img src={user} alt="bell" />
                  <div className={IndexStyles.headerNotiBall}></div>
                </div>
                <div>
                  <span>Toheeb Olatoyinbo</span>
                </div>
              </div>
            </div>
            <div>
              <Outlet />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
}
