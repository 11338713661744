import React from 'react';
import footerStyles from './footer.module.scss'
import { FaFacebookF, FaInstagram, FaYoutube } from 'react-icons/fa';
import logo from "../../Images/TIWAlogo.svg";

export default function FooterPage(props) {
    return (
        <section>
            <div className={footerStyles.holdFooter}>
                <div className={footerStyles.holdAbout}>
                    <h2 className={footerStyles.aboutTextH2}>About Tiwa</h2>
                    <ul>
                        <li>Real Estate Videos</li>
                        <li>Blog</li>
                        <li>Book an Inspection</li>
                        <li>How To Use Zylus Portal</li>
                        <li>Purchase</li>
                    </ul>
                </div>
                <div className={footerStyles.holdAbout}>
                    <h2 className={footerStyles.aboutTextH2}>Help & Support</h2>
                    <ul>
                        <li>FAQS</li>
                        <li>Customer Care</li>
                        <li>Book an Inspection</li>
                        <li>How To Use Zylus Portal</li>
                        <li>News</li>
                    </ul>
                </div>
                <div className={footerStyles.holdAbout}>
                    <h2 className={footerStyles.aboutTextH2}>Quick Link</h2>
                    <ul>
                        <li>Real Estate Videos</li>
                        <li>Blog</li>
                        <li>Our Estates</li>
                        <li>Our Careers</li>
                        <li>Purchase</li>
                    </ul>
                </div>
                <div className={footerStyles.holdAbout}>
                    <h2 className={footerStyles.aboutTextH2}>Our Location</h2>
                    <p className={footerStyles.aboutText}>Head Office: 4A Omon Ebhomenye Street, Off Admiralty Way, Lekki Phase 1, Lagos, Nigeria.</p>
                    <p className={footerStyles.aboutText}>Mainland Cooperate Office: 65, Allen Avenue, First Bank Bus-stop, Ikeja, Lagos, Nigeria.</p>
                    <div className={footerStyles.holdIconsandLogo}>
                        <div className={footerStyles.holdIcons}>
                            <a href='https://www.facebook.com/TiwaGardenCity/' target="_blank" rel="noreferrer">
                                <div className={footerStyles.hIcons}>
                                    <FaFacebookF />
                                </div>
                            </a>
                            <a href='https://www.instagram.com/tiwagardencity/' target="_blank" rel="noreferrer" style={{ margin: "0 10px" }}>
                                <div className={footerStyles.hIcons}>
                                    <FaInstagram />
                                </div>
                            </a>
                            <a href='https://www.instagram.com/tiwagardencity/' target="_blank" rel="noreferrer">
                                <div className={footerStyles.hIcons}>
                                    <FaYoutube />
                                </div>
                            </a>
                        </div>
                        <div>
                            <img src={logo} alt="log" className={footerStyles.logoImage} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
