import React, { useEffect, useState } from 'react';
import m8 from "../../Images/m8.png";
import m9 from "../../Images/m9.png";
import m10 from "../../Images/m10.png";
import m11 from "../../Images/m11.png";
import m12 from "../../Images/m12.png";
import m2 from "../../Images/m2.png";
import logo from "../../Images/TIWAlogo.svg";
import icons1 from "../../Images/sewer 1.png";
import icons2 from "../../Images/surveillance 1.png";
import icons3 from "../../Images/enterprise 1.png";
import icons4 from "../../Images/water-slide 1.png";
import icons5 from "../../Images/helipad 1.png";
import icons6 from "../../Images/park 1.png";
import p1 from "../../Images/p1.jpg";
import p2 from "../../Images/p2.png";
import p3 from "../../Images/p3.png";
import p4 from "../../Images/p4.png";
import p5 from "../../Images/p5.png";
import p6 from "../../Images/p6.png";
import p8 from "../../Images/p8.png";
import p9 from "../../Images/p9.png";
import p10 from "../../Images/p10.jpg";
import landingPang1 from "../../Images/LandPage1.png";
import refine from "../../Images/refine.png";
import LekkiFreeTradeZone from "../../Images/Lekki-Free-Trade-Zone.png";
import airportterminal from "../../Images/airport-terminal.png";
import dronshot from "../../Images/dronshot.png";
import deep from "../../Images/deep.png";
import green from "../../Images/green.png";
import school from "../../Images/school.png";
import HomeStyle from "./Home.module.scss";
import { HiMenu } from 'react-icons/hi';
import { MdLocationOn } from 'react-icons/md';
import { FaWhatsapp } from 'react-icons/fa';
import { BsFillPlayFill } from 'react-icons/bs';
import { BiRightArrowAlt } from 'react-icons/bi';
import { NavLink, Link } from 'react-router-dom';
import FooterPage from '../footer/footer';
import tiwaForm from "../../Images/tiwaform.pdf";
import TIWABrochure from "../../Images/TIWABrochure.pdf";
import { Modal, Button, Form } from 'react-bootstrap';
import { db } from "../../firebase-config"
import { collection, addDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';

function Home(seconds) {
    const [load, setLoad] = useState(false);
    const [modalShow1, setModalShow1] = useState(false);
    const [imageChange, setImageChange] = useState(0);
    const [menu, setMenu] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [show, setShow] = useState(false);
    const [fullName2, setFullName2] = useState("");
    const [phone2, setPhone2] = useState("");
    const [email2, setEmail2] = useState("");
    const [aboutUs, setAboutUs] = useState("");
    const [broker, setBroker] = useState("");
    const [realtor, setRealtor] = useState("");
    const [days, setDays] = useState("");
    const [hours, setHours] = useState("");
    const [min, setMin] = useState("");
    const [sec, setSec] = useState("");

    const contactCollectionRef2 = collection(db, "reservation");
    const navigate = useNavigate();
    const current = new Date();

    const [show1, setShow1] = useState(true);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const imagin = (index) => {
        setImageChange(index)
    }

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const menuMe = () => {
        setMenu(!menu);
    }
    useEffect(() => {
        const intervalId = setTimeout(() => {
            setDays(`${28 - current.getDate()}`)
            setHours(`${current.getHours()}`)
            setMin(`${current.getMinutes()}`)
            setSec(`${current.getSeconds()}`)
        }, 1000);
        return () => clearInterval(intervalId);
    }, [sec]);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    })

    const handleSubmit2 = (e) => {
        e.preventDefault();
        setLoad(true);
        addDoc(contactCollectionRef2, {
            fullName: fullName2,
            phone: phone2,
            email: email2,
            HowDidYouHearAboutUs: aboutUs,
            AreYouARealtorOrBroker: broker,
            AreYouWorkingWithARealtor: realtor,
            date: `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`,
            read: false,
        })
            .then(() => {
                setLoad(false);
                setModalShow1(true);
                navigate("/success")
                setFullName2("")
                setPhone2("")
                setEmail2("")
                setAboutUs("")
                setBroker("")
                setRealtor("")
            })
            .catch(error => {
                alert("Error occurred", error)
                setLoad(false);
            })
    }

    const isMobile = width <= 768;
    return (
      <section>
        <div>
          <div className={HomeStyle.LandImage}>
            <img src={landingPang1} alt="Landing" />
          </div>
          <div className={HomeStyle.holdNav}>
            <div className={HomeStyle.holdLogo}>
              <img src={logo} alt="Landing" className={HomeStyle.logo} />
            </div>
            {isMobile ? (
              <div className={HomeStyle.holdHandBurger}>
                <span>
                  <a href="tel:+2347061032706" rel="noopener noreferrer">
                    <button className={HomeStyle.buyTiwaNow}>Call Us!</button>
                  </a>
                </span>
                <div className={HomeStyle.holdHi} onClick={menuMe}>
                  <HiMenu className={HomeStyle.hi} />
                </div>
              </div>
            ) : null}
            {isMobile ? (
              <>
                {menu ? (
                  <div className={HomeStyle.navLink}>
                    <span>
                      <NavLink
                        style={({ isActive }) => {
                          return isActive
                            ? { color: "#019785", textDecoration: "underline" }
                            : {};
                        }}
                        to={"/phase1"}
                      >
                        Home
                      </NavLink>
                    </span>
                    <span>
                      <NavLink
                        style={({ isActive }) => {
                          return isActive
                            ? { color: "#019785", textDecoration: "underline" }
                            : {};
                        }}
                        to={"/price"}
                      >
                        Pricing
                      </NavLink>
                    </span>
                    <span>
                      <NavLink
                        style={({ isActive }) => {
                          return isActive
                            ? { color: "#019785", textDecoration: "underline" }
                            : {};
                        }}
                        to={"/gallery"}
                      >
                        Gallery
                      </NavLink>
                    </span>
                    <span>
                      <NavLink
                        style={({ isActive }) => {
                          return isActive
                            ? { color: "#019785", textDecoration: "underline" }
                            : {};
                        }}
                        to={"/about"}
                      >
                        About
                      </NavLink>
                    </span>
                    <span>
                      <NavLink
                        style={({ isActive }) => {
                          return isActive
                            ? { color: "#019785", textDecoration: "underline" }
                            : {};
                        }}
                        to={"/contact"}
                      >
                        Contact
                      </NavLink>
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              <div className={HomeStyle.navLink}>
                <span>
                  <NavLink
                    style={({ isActive }) => {
                      return isActive
                        ? { color: "#019785", textDecoration: "underline" }
                        : {};
                    }}
                    to={"phase1"}
                  >
                    Home
                  </NavLink>
                </span>
                <span>
                  <NavLink
                    style={({ isActive }) => {
                      return isActive
                        ? { color: "#019785", textDecoration: "underline" }
                        : {};
                    }}
                    to={"/price"}
                  >
                    Pricing
                  </NavLink>
                </span>
                <span>
                  <NavLink
                    style={({ isActive }) => {
                      return isActive
                        ? { color: "#019785", textDecoration: "underline" }
                        : {};
                    }}
                    to={"/gallery"}
                  >
                    Gallery
                  </NavLink>
                </span>
                <span>
                  <NavLink
                    style={({ isActive }) => {
                      return isActive
                        ? { color: "#019785", textDecoration: "underline" }
                        : {};
                    }}
                    to={"/about"}
                  >
                    About
                  </NavLink>
                </span>
                <span>
                  <NavLink
                    style={({ isActive }) => {
                      return isActive
                        ? { color: "#019785", textDecoration: "underline" }
                        : {};
                    }}
                    to={"/contact"}
                  >
                    Contact
                  </NavLink>
                </span>
                <span>
                  <a href="tel:+2347061032706" rel="noopener noreferrer">
                    <button className={HomeStyle.buyTiwaNow}>Call Us!</button>
                  </a>
                </span>
              </div>
            )}
          </div>
          <div className={HomeStyle.LandingText}>
            <div>
              <div>
                <h1>Tiwa Garden City</h1>
                <p>
                  Tiwa Garden City not only guarantees you the work-life balance
                  you deserve but also brings to you a perfect blend with
                  technology and nature.
                </p>
                <div className={HomeStyle.holdButton}>
                  <div className={HomeStyle.holdButton2}>
                    <Link to="/price">
                      <button type="button">
                        See Pricing
                        <BiRightArrowAlt />{" "}
                      </button>
                    </Link>
                    <button
                      type="button"
                      className={HomeStyle.button2Play}
                      onClick={handleShow}
                    >
                      <BsFillPlayFill /> Watch Video
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={HomeStyle.holdOffer}>
            <div className={HomeStyle.offer}>
              <h3>Tiwa Garden Estate</h3>
              <p>
                <MdLocationOn
                  style={{ fontSize: "20px", marginRight: "5px" }}
                />
                Lekki Epe Expressway
              </p>
              <p>Certificate of Occupancy</p>
              <div className={HomeStyle.holdOrange}>
                <div className={HomeStyle.orangeLine}></div>
              </div>
              <div className={HomeStyle.holdIcons2}>
                <div className={HomeStyle.holdIcons}>
                  <div className={HomeStyle.icons}>
                    <img
                      src={icons1}
                      alt="icon"
                      className={HomeStyle.iconsKoko}
                    />
                    <p>Drainage System</p>
                  </div>
                  <div className={HomeStyle.icons}>
                    <img
                      src={icons2}
                      alt="icon"
                      className={HomeStyle.iconsKoko}
                    />
                    <p>Surveliance</p>
                  </div>
                  <div className={HomeStyle.icons}>
                    <img
                      src={icons3}
                      alt="icon"
                      className={HomeStyle.iconsKoko}
                    />
                    <p>Work City</p>
                  </div>
                  <div className={HomeStyle.icons}>
                    <img
                      src={icons4}
                      alt="icon"
                      className={HomeStyle.iconsKoko}
                    />
                    <p>Water Park</p>
                  </div>
                  <div className={HomeStyle.icons}>
                    <img
                      src={icons5}
                      alt="icon"
                      className={HomeStyle.iconsKoko}
                    />
                    <p>Helipad</p>
                  </div>
                  <div className={HomeStyle.icons}>
                    <img
                      src={icons6}
                      alt="icon"
                      className={HomeStyle.iconsKoko}
                    />
                    <p>Recreational Park</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={HomeStyle.resistend}>
            <div
              className={HomeStyle.holdOrange}
              style={{ marginBottom: "30px" }}
            >
              <div className={HomeStyle.orangeLine}></div>
            </div>
            <h3 style={{ marginTop: "40px" }}>Product Types</h3>
          </div>
          <div className={HomeStyle.resident}>
            <div className={HomeStyle.residentMe}>
              <div>
                <h1>Residential</h1>
                <p>
                  TIWA is situated in an environment that is closely knitted to
                  exceptional landmarks like:
                  <br />
                  <span>
                    Alaro City, Lekki Refinery, Lekki Free Trade Zone, New
                    International Airport, Deep Sea Port etc.
                  </span>
                </p>
                <Link to={"/price"}>
                  <button>See Pricing</button>
                </Link>
              </div>
              {/* <div className={HomeStyle.holdImageRes}>
                        <img src={TIWAPOST22} alt="Landing" />
                    </div> */}
            </div>
            <div className={HomeStyle.commercialMe}>
              <div>
                <h1>Commercial</h1>
                <p>
                  TIWA project is set to meet the needs of people yearning for a
                  good investment project.
                  <br />
                  <span>
                    Are you looking for a project that can give you good returns
                    in a short while? Look no further, TIWA Is Here!
                  </span>
                </p>
                <a href="https://tiwacommercialhub.com/" target="_blank">
                  <button>Learn More</button>
                </a>
              </div>
              {/* <div className={HomeStyle.holdImageRes}>
                        <img src={TIWAPOST} alt="Landing" />
                    </div> */}
            </div>
          </div>
        </div>
        <div className={HomeStyle.holdBanks}>
          <div className={HomeStyle.holdBanks2}>
            <div className={HomeStyle.holdText}>
              <div className={HomeStyle.holdOrange}>
                <div className={HomeStyle.orangeLine}></div>
              </div>
              <p className={HomeStyle.TextWhat}>
                Our Trusted Partners & our proud investors around the globe
              </p>
            </div>
            <marquee behavior="alternate">
              <div className={HomeStyle.holdImageBankStone}>
                <div>
                  <img
                    src={p1}
                    alt="banks"
                    className={HomeStyle.holdImageKoMeB}
                  />
                </div>
                <div>
                  <img
                    src={p2}
                    alt="banks"
                    className={HomeStyle.holdImageKoMeB}
                  />
                </div>
                <div>
                  <img
                    src={p3}
                    alt="banks"
                    className={HomeStyle.holdImageKoMeB}
                  />
                </div>
                <div>
                  <img
                    src={p4}
                    alt="banks"
                    className={HomeStyle.holdImageKoMeB}
                  />
                </div>
                <div>
                  <img
                    src={p5}
                    alt="banks"
                    className={HomeStyle.holdImageKoMeB}
                  />
                </div>
                <div>
                  <img
                    src={p6}
                    alt="banks"
                    className={HomeStyle.holdImageKoMeB}
                  />
                </div>
                <div>
                  <img
                    src={p8}
                    alt="banks"
                    className={HomeStyle.holdImageKoMeB}
                  />
                </div>
                <div>
                  <img
                    src={p9}
                    alt="banks"
                    className={HomeStyle.holdImageKoMeB}
                  />
                </div>
                <div>
                  <img
                    src={p10}
                    alt="banks"
                    className={HomeStyle.holdImageKoMeB}
                  />
                </div>
              </div>
            </marquee>
          </div>
        </div>
        <div>
          <div className={HomeStyle.holdRefinery}>
            <div>
              <div className={HomeStyle.holdOrange}>
                <div className={HomeStyle.orangeLine}></div>
              </div>
              <h3 style={{ marginTop: "40px" }}>Landmarks</h3>
            </div>
            <div className={HomeStyle.holdRefinery2}>
              <div className={HomeStyle.boxOneMe}>
                <div className={HomeStyle.refineMeOne}>
                  <img src={refine} alt="refine" width="100%" height="100%" />
                </div>
                <div className={HomeStyle.refineMeOneText}>
                  <h5>Dangote Refinery</h5>
                  <p>
                    The Dangote refinery is just 30 minutes away from Tiwa
                    Garden City. They are in a very close Proximity
                  </p>
                </div>
              </div>
              <div className={HomeStyle.boxOneMe}>
                <div className={HomeStyle.refineMeOne}>
                  <img
                    src={LekkiFreeTradeZone}
                    alt="refine"
                    width="100%"
                    height="100%"
                  />
                </div>
                <div className={HomeStyle.refineMeOneText}>
                  <h5>Lekki Free Trade</h5>
                  <p>
                    The Lekki Free Trade is just 30 minutes away from Tiwa
                    Garden City. They are in a very close Proximity
                  </p>
                </div>
              </div>
              <div className={HomeStyle.boxOneMe}>
                <div className={HomeStyle.refineMeOne}>
                  <img
                    src={airportterminal}
                    alt="refine"
                    width="100%"
                    height="100%"
                  />
                </div>
                <div className={HomeStyle.refineMeOneText}>
                  <h5>New Epe International Airport</h5>
                  <p>
                    The Lekki Free Trade is just 30 minutes away from Tiwa
                    Garden City. They are in a very close Proximity
                  </p>
                </div>
              </div>
              <div className={HomeStyle.boxOneMe}>
                <div className={HomeStyle.refineMeOne}>
                  <img src={green} alt="refine" width="100%" height="100%" />
                </div>
                <div className={HomeStyle.refineMeOneText}>
                  <h5>Lekki Deep Sea Port</h5>
                  <p>
                    The Dangote refinery is just 30 minutes away from Tiwa
                    Garden City. They are in a very close Proximity
                  </p>
                </div>
              </div>
              <div className={HomeStyle.boxOneMe}>
                <div className={HomeStyle.refineMeOne}>
                  <img src={deep} alt="refine" width="100%" height="100%" />
                </div>
                <div className={HomeStyle.refineMeOneText}>
                  <h5>Pan Atlantic University</h5>
                  <p>
                    The Dangote refinery is just 30 minutes away from Tiwa
                    Garden City. They are in a very close Proximity
                  </p>
                </div>
              </div>
              <div className={HomeStyle.boxOneMe}>
                <div className={HomeStyle.refineMeOne}>
                  <img src={school} alt="refine" width="100%" height="100%" />
                </div>
                <div className={HomeStyle.refineMeOneText}>
                  <h5>Dangote Refinery</h5>
                  <p>
                    The Dangote refinery is just 30 minutes away from Tiwa
                    Garden City. They are in a very close Proximity
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={HomeStyle.holdWhatToExpect}>
          <div className={HomeStyle.expect}>
            <div className={HomeStyle.holdOrange}>
              <div className={HomeStyle.orangeLine}></div>
            </div>
            <h3 style={{ marginTop: "40px" }}>What To Expect</h3>
          </div>
          <div className={HomeStyle.holdImage1}>
            <div className={HomeStyle.holdImage2}>
              <div className={HomeStyle.holdImage}>
                <div>
                  {imageChange === 0 ? (
                    <img
                      src={m8}
                      alt="Expect"
                      className={HomeStyle.imageKoko}
                    />
                  ) : null}
                  {imageChange === 1 ? (
                    <img
                      src={m9}
                      alt="Expect"
                      className={HomeStyle.imageKoko}
                    />
                  ) : null}
                  {imageChange === 2 ? (
                    <img
                      src={m10}
                      alt="Expect"
                      className={HomeStyle.imageKoko}
                    />
                  ) : null}
                  {imageChange === 3 ? (
                    <img
                      src={m11}
                      alt="Expect"
                      className={HomeStyle.imageKoko}
                    />
                  ) : null}
                  {imageChange === 4 ? (
                    <img
                      src={m12}
                      alt="Expect"
                      className={HomeStyle.imageKoko}
                    />
                  ) : null}
                  <div className={HomeStyle.holdCircle}>
                    <div className={HomeStyle.holdCircle2}>
                      <div
                        className={
                          imageChange === 0
                            ? HomeStyle.circleActive
                            : HomeStyle.circle
                        }
                        onClick={() => {
                          imagin(0);
                        }}
                      ></div>
                      <div
                        className={
                          imageChange === 1
                            ? HomeStyle.circleActive
                            : HomeStyle.circle
                        }
                        onClick={() => {
                          imagin(1);
                        }}
                      ></div>
                      <div
                        className={
                          imageChange === 2
                            ? HomeStyle.circleActive
                            : HomeStyle.circle
                        }
                        onClick={() => {
                          imagin(2);
                        }}
                      ></div>
                      <div
                        className={
                          imageChange === 3
                            ? HomeStyle.circleActive
                            : HomeStyle.circle
                        }
                        onClick={() => {
                          imagin(3);
                        }}
                      ></div>
                      <div
                        className={
                          imageChange === 4
                            ? HomeStyle.circleActive
                            : HomeStyle.circle
                        }
                        onClick={() => {
                          imagin(4);
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className={HomeStyle.textKoko}>
                  <h5>Delivering Homes Not Houses</h5>
                  <p>
                    Home Is Your Mast And Your Anchor. This Is Where Kings And
                    Queens Belong. We are a real estate company providing
                    marketing, advisory and developmental services across the
                    entire real estate value chain in Nigeria and across Africa.
                  </p>
                  <Link to="/gallery">
                    <button type="button">SEE GALLERY</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p id="contect"></p>
        <div className={HomeStyle.holdAllForm}>
          <div className={HomeStyle.holdImageForm}>
            <div
              className={HomeStyle.holdImageForm2sp}
              style={{ marginBottom: "20px" }}
            >
              <img
                src={m2}
                alt="expect form"
                className={HomeStyle.holdImageYou}
              />
              <div className={HomeStyle.holdImageTextV}>
                <h5 className={HomeStyle.smartCity}>Smart City</h5>
                <p className={HomeStyle.weBuild}>
                  We are building the smart city for Smart People. Be part of
                  the
                </p>
                <p className={HomeStyle.weBuild2}>NEW FUTURE</p>
                <Link to={TIWABrochure} target="_blank" download="TIWABrochure">
                  <button type="button" className={HomeStyle.seeBro}>
                    DOWNLOAD BROCHURE
                  </button>
                </Link>
              </div>
            </div>
            <div className={HomeStyle.holdImageForm2sp}>
              <img
                src={dronshot}
                alt="expect form"
                className={HomeStyle.holdImageYou2}
              />
              <div className={HomeStyle.holdImageTextV}>
                <h5 className={HomeStyle.smartCity}>
                  Biggest Entertainment City
                </h5>
                <p className={HomeStyle.weBuild}>
                  We are building the first biggest entertainment city in
                  africa. Be a part of this GEM
                </p>
                <p className={HomeStyle.weBuild2}>NEW FUTURE</p>
                <Link to={tiwaForm} target="_blank" download="tiwaForm">
                  <button type="button" className={HomeStyle.seeBro}>
                    DOWNLOAD
                    <br /> FAQ & FORM
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className={HomeStyle.holdImageForm2}>
            <h4 style={{ margin: "10px 0" }}>Make Your Reservation</h4>
            <br />
            <Form onSubmit={handleSubmit2}>
              <Form.Group className="mb-3" controlId="formGridAddress2">
                <Form.Control
                  placeholder="Your Full Name"
                  style={{ padding: "15px" }}
                  value={fullName2}
                  onChange={(e) => setFullName2(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGridEmail">
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  style={{ padding: "15px" }}
                  value={email2}
                  onChange={(e) => setEmail2(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGridAddress1">
                <Form.Control
                  placeholder="Enter Phone"
                  style={{ padding: "15px" }}
                  type="phone"
                  value={phone2}
                  onChange={(e) => setPhone2(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Select
                aria-label="Default select example"
                className="mb-3"
                style={{ padding: "15px" }}
                value={aboutUs}
                onChange={(e) => setAboutUs(e.target.value)}
                required
              >
                <option value="">How did you hear about us?</option>
                <option value="Facebook">Facebook</option>
                <option value="Billboard">Billboard</option>
                <option value="Instagram"> Instagram</option>
                <option value="Twitter">Twitter</option>
                <option value="Google">Google</option>
                <option value="LinkedIn">LinkedIn</option>
                <option value="Referred by someone">Referred by someone</option>
              </Form.Select>

              <Form.Select
                aria-label="Default select example"
                className="mb-3"
                style={{ padding: "15px" }}
                value={broker}
                onChange={(e) => setBroker(e.target.value)}
                required
              >
                <option value="">Are you a realtor or broker?</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Form.Select>

              <Form.Select
                aria-label="Default select example"
                className="mb-3"
                style={{ padding: "15px" }}
                value={realtor}
                onChange={(e) => setRealtor(e.target.value)}
                required
              >
                <option value="">Are you working with a realtor?</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Form.Select>
              <button type="submit" className={HomeStyle.subMitNowNit}>
                {load ? "Loading..." : "Submit"}
              </button>
            </Form>
          </div>
        </div>
        <div className={HomeStyle.holdSubcscribe}>
          <div className={HomeStyle.holdSubcscribe2}>
            <p className={HomeStyle.holdTextScr2}>
              Subscribe To Our Newsletter To Get Updates And Stay Connected
            </p>
            <div>
              <div>
                <input
                  type="email"
                  placeholder="Email"
                  className={HomeStyle.holdInputEmail}
                />
                <button type="submit" className={HomeStyle.subButinNow}>
                  Subcribe
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={HomeStyle.footMintColor}>
          <div></div>
        </div>
        <br />
        <div>
          <FooterPage />
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h5>TIWA GARDEN CITY - Estate Launching.</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <iframe
              width="100%"
              height="300px"
              src="https://www.youtube.com/embed/X7Qx4OXZjgU"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
            {/* <iframe width="100%" height="300px" src="https://www.youtube.com/embed/yiyPyG3xiTA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <a
          href="https://wa.me/2347061032706"
          className={HomeStyle.whatsapp_float}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaWhatsapp className={HomeStyle.whatsappIcon} />
        </a>
      </section>
    );
}

export default Home;