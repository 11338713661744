import React from 'react';
import Layout1 from "./Layout/Layout1";

function Reservation() {
    return (
        <React.Fragment>
            <div>
                <Layout1 />
            </div>
        </React.Fragment>
    );
}

export default Reservation;