import React, { useState, useEffect } from 'react';
import pricingStyle from "./pricing.module.scss";
import { NavLink, Link, useNavigate } from 'react-router-dom';
import logo from "../../Images/TIWAlogo.svg";
import FooterPage from '../footer/footer';
import { Form, Button, Modal } from 'react-bootstrap';
import { HiMenu } from 'react-icons/hi';
import { db } from "../../firebase-config"
import { collection, addDoc } from "firebase/firestore";
import { FaWhatsapp } from 'react-icons/fa';

function Pricing(props) {
    const [load, setLoad] = useState(false);
    const [modalShow1, setModalShow1] = useState(false);
    const [switchs, setSwitchs] = useState(0);
    const [switchs2, setSwitchs2] = useState(0);
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const [menu, setMenu] = useState(false);
    const [fullName, setFullName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [cupomCode, setCupomCode] = useState("");
    const [notCoupon, setNotCoupon] = useState("");
    const [days, setDays] = useState("");
    const [hours, setHours] = useState("");
    const [min, setMin] = useState("");
    const [sec, setSec] = useState("");
    const navigate = useNavigate();
    const current = new Date();

    const [fullName2, setFullName2] = useState("");
    const [phone2, setPhone2] = useState("");
    const [email2, setEmail2] = useState("");
    const [aboutUs, setAboutUs] = useState("");
    const [broker, setBroker] = useState("");
    const [realtor, setRealtor] = useState("");
    const [width, setWidth] = useState(window.innerWidth);
    const naviagte = useNavigate()
    const contactCollectionRef = collection(db, "makeEnquiry");
    const contactCollectionRef2 = collection(db, "reservation");

    const [show2, setShow2] = useState(true);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    useEffect(() => {
        const intervalId = setTimeout(() => {
            setDays(`${28 - current.getDate()}`)
            setHours(`${current.getHours()}`)
            setMin(`${current.getMinutes()}`)
            setSec(`${current.getSeconds()}`)
        }, 1000);
        return () => clearInterval(intervalId);
    }, [sec]);

    const clickMe = (index) => {
        setSwitchs(index);
    }

    const clickMe2 = (index) => {
        setSwitchs2(index);
    }

    const changeLink = () => {
        naviagte("/")
    }

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const menuMe = () => {
        setMenu(!menu);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    })
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoad(true);
        if (cupomCode.toLowerCase() === "tiwa142756" || cupomCode === "") {
            setNotCoupon("");
            addDoc(contactCollectionRef, {
                fullName: fullName,
                phone: phone,
                email: email,
                cupomCode: cupomCode,
                message: message,
                date: `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`,
                read: false,
            })
                .then(() => {
                    setLoad(false);
                    setShow(false)
                    navigate("/success")
                    setModalShow1(true);
                    setFullName("")
                    setPhone("")
                    setEmail("")
                    setMessage("")
                })
                .catch(error => {
                    alert("Error occurred", error)
                    setLoad(false);
                })
        } else {
            setNotCoupon("Wrong cupom code");
            setLoad(false);
        }
    }

    const handleSubmit2 = (e) => {
        e.preventDefault();
        setLoad(true);
        addDoc(contactCollectionRef2, {
            fullName: fullName2,
            phone: phone2,
            email: email2,
            HowDidYouHearAboutUs: aboutUs,
            AreYouARealtorOrBroker: broker,
            AreYouWorkingWithARealtor: realtor,
            date: `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`,
            read: false,
        })
            .then(() => {
                setLoad(false);
                setShow1(false)
                setModalShow1(true);
                navigate("/success")
                setFullName2("")
                setPhone2("")
                setEmail2("")
                setAboutUs("")
                setBroker("")
                setRealtor("")
            })
            .catch(error => {
                alert("Error occurred", error)
                setLoad(false);
            })
    }


    const isMobile = width <= 768;
    return (
      <section style={{ backgroundColor: "#eeeeee" }}>
        <div className={pricingStyle.holdNav}>
          <div className={pricingStyle.holdLogo}>
            <img
              src={logo}
              alt="Landing"
              className={pricingStyle.logo}
              onClick={changeLink}
            />
          </div>
          {isMobile ? (
            <div className={pricingStyle.holdHandBurger}>
              <span>
                <a href="tel:+2347061032706" rel="noopener noreferrer">
                  <button className={pricingStyle.buyTiwaNow}>
                    Call Us Now !
                  </button>
                </a>
              </span>
              <div className={pricingStyle.holdHi} onClick={menuMe}>
                <HiMenu className={pricingStyle.hi} />
              </div>
            </div>
          ) : null}
          {isMobile ? (
            <>
              {menu ? (
                <div className={pricingStyle.navLink}>
                  <span>
                    <NavLink
                      style={({ isActive }) => {
                        return isActive
                          ? { color: "#019785", textDecoration: "underline" }
                          : {};
                      }}
                      to={"/phase1"}
                    >
                      Home
                    </NavLink>
                  </span>
                  <span>
                    <NavLink
                      style={({ isActive }) => {
                        return isActive
                          ? { color: "#019785", textDecoration: "underline" }
                          : {};
                      }}
                      to={"/price"}
                    >
                      Pricing
                    </NavLink>
                  </span>
                  <span>
                    <NavLink
                      style={({ isActive }) => {
                        return isActive
                          ? { color: "#019785", textDecoration: "underline" }
                          : {};
                      }}
                      to={"/gallery"}
                    >
                      Gallery
                    </NavLink>
                  </span>
                  <span>
                    <NavLink
                      style={({ isActive }) => {
                        return isActive
                          ? { color: "#019785", textDecoration: "underline" }
                          : {};
                      }}
                      to={"/about"}
                    >
                      About
                    </NavLink>
                  </span>
                  <span>
                    <NavLink
                      style={({ isActive }) => {
                        return isActive
                          ? { color: "#019785", textDecoration: "underline" }
                          : {};
                      }}
                      to={"/contact"}
                    >
                      Contact
                    </NavLink>
                  </span>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            <div className={pricingStyle.navLink}>
              <span>
                <NavLink
                  style={({ isActive }) => {
                    return isActive
                      ? { color: "#019785", textDecoration: "underline" }
                      : {};
                  }}
                  to={"/phase1"}
                >
                  Home
                </NavLink>
              </span>
              <span>
                <NavLink
                  style={({ isActive }) => {
                    return isActive
                      ? { color: "#019785", textDecoration: "underline" }
                      : {};
                  }}
                  to={"/price"}
                >
                  Pricing
                </NavLink>
              </span>
              <span>
                <NavLink
                  style={({ isActive }) => {
                    return isActive
                      ? { color: "#019785", textDecoration: "underline" }
                      : {};
                  }}
                  to={"/gallery"}
                >
                  Gallery
                </NavLink>
              </span>
              <span>
                <NavLink
                  style={({ isActive }) => {
                    return isActive
                      ? { color: "#019785", textDecoration: "underline" }
                      : {};
                  }}
                  to={"/about"}
                >
                  About
                </NavLink>
              </span>
              <span>
                <NavLink
                  style={({ isActive }) => {
                    return isActive
                      ? { color: "#019785", textDecoration: "underline" }
                      : {};
                  }}
                  to={"/contact"}
                >
                  Contact
                </NavLink>
              </span>
              <span>
                <a href="tel:+2347061032706" rel="noopener noreferrer">
                  <button className={pricingStyle.buyTiwaNow}>
                    Call Us Now!
                  </button>
                </a>
              </span>
            </div>
          )}
        </div>
        <div>
          <div>
            <div>
              <div>
                <div className={pricingStyle.holdContent}>
                  {/* <div className={pricingStyle.holdTime2Now} >
                                    <div className={pricingStyle.holdTime} >
                                        <h4 className={pricingStyle.morning}>Take Action Now! Don't Miss Out on this Offer.</h4>
                                        <div className={pricingStyle.timerStart}>
                                            <div className={pricingStyle.timerStart2}>
                                                <div>
                                                    <h3 className={pricingStyle.h3}>{days}</h3>
                                                    <p className={pricingStyle.p}>Days</p>
                                                </div>
                                                <div>
                                                    <h3 className={pricingStyle.h3}>{hours}</h3>
                                                    <p className={pricingStyle.p}>Hours</p>
                                                </div>
                                                <div>
                                                    <h3 className={pricingStyle.h3}>{min}</h3>
                                                    <p className={pricingStyle.p}>Minutes</p>
                                                </div>
                                                <div>
                                                    <h3 className={pricingStyle.h3}>{sec}</h3>
                                                    <p className={pricingStyle.p}>Seconds</p>
                                                </div>
                                            </div>
                                        </div>
                                    </ div>
                                </ div> */}
                  <div style={{ marginTop: "70px" }}>
                    <h3 className={pricingStyle.OurPricing}>Our Pricing</h3>
                    <p className={pricingStyle.check}>
                      Checkout The <span>Pricing and Projections</span>
                      <br /> For Tiwa Garden City
                    </p>
                  </div>
                  <div className={pricingStyle.holdPricingPackage}>
                    <div className={pricingStyle.holdPricingPackage2}>
                      <button
                        type="button"
                        className={
                          switchs === 0
                            ? pricingStyle.active
                            : pricingStyle.notActive
                        }
                        onClick={() => {
                          clickMe(0);
                        }}
                      >
                        Pre-Launch <br /> Price
                      </button>
                      <button
                        type="button"
                        className={
                          switchs === 1
                            ? pricingStyle.active
                            : pricingStyle.notActive
                        }
                        onClick={() => {
                          clickMe(1);
                        }}
                      >
                        Price Increase
                        <br /> Projection
                      </button>
                    </div>
                  </div>
                  <div
                    className={
                      switchs === 0
                        ? pricingStyle.display + pricingStyle.holdList
                        : pricingStyle.notDisplay
                    }
                  >
                    <div className={pricingStyle.holdLunch}>
                      <div className={pricingStyle.image}>
                        <div className={pricingStyle.imageKokos}>
                          <div className={pricingStyle.imageKoko}>
                            <img
                              src={logo}
                              alt="Landing"
                              className={pricingStyle.logo}
                            />
                            <h3 style={{ color: "#ff9201" }}>N27,000,000</h3>
                            <p style={{ margin: 0 }}>
                              Certificate of Occupancy
                            </p>
                            <p>(500 Square Meter)</p>
                            <button
                              onClick={handleShow}
                              className={pricingStyle.buyNow}
                            >
                              Secure A Plot
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className={pricingStyle.image}>
                        <div className={pricingStyle.imageKokos}>
                          <div className={pricingStyle.imageKoko}>
                            <img
                              src={logo}
                              alt="Landing"
                              className={pricingStyle.logo}
                            />
                            <h3 style={{ color: "#ff9201" }}>N23,000,000</h3>
                            <p style={{ margin: 0 }}>
                              Certificate of Occupancy
                            </p>
                            <p>(417 Square Meter)</p>
                            <button
                              onClick={handleShow}
                              className={pricingStyle.buyNow}
                            >
                              Secure A Plot
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className={pricingStyle.image}>
                        <div className={pricingStyle.imageKokos}>
                          <div className={pricingStyle.imageKoko}>
                            <img
                              src={logo}
                              alt="Landing"
                              className={pricingStyle.logo}
                            />
                            <h3 style={{ color: "#ff9201" }}>N17,000,000</h3>
                            <p style={{ margin: 0 }}>
                              Certificate of Occupancy
                            </p>
                            <p>(300 Square Meter)</p>
                            <button
                              onClick={handleShow}
                              className={pricingStyle.buyNow}
                            >
                              Secure A Plot
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className={pricingStyle.image}>
                        <div className={pricingStyle.imageKokos}>
                          <div className={pricingStyle.imageKoko}>
                            <img
                              src={logo}
                              alt="Landing"
                              className={pricingStyle.logo}
                            />
                            <h3 style={{ color: "#ff9201" }}>N13,500,000</h3>
                            <p style={{ margin: 0 }}>
                              Certificate of Occupancy
                            </p>
                            <p>(250 Square Meter)</p>
                            <button
                              onClick={handleShow}
                              className={pricingStyle.buyNow}
                            >
                              Secure A Plot
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      switchs === 1
                        ? pricingStyle.display + pricingStyle.holdList
                        : pricingStyle.notDisplay
                    }
                  >
                    <div className={pricingStyle.holdList}>
                      <h3>Price Increase Projection (1st March 2023)</h3>
                      <div className={pricingStyle.holdContest}>
                        <div className={pricingStyle.image}>
                          <div className={pricingStyle.imageKokos}>
                            <div className={pricingStyle.imageKoko}>
                              <img
                                src={logo}
                                alt="Landing"
                                className={pricingStyle.logo}
                                height="150"
                              />
                              <h3>1st March 2023</h3>
                              <p>Approach view and gatehouse</p>
                            </div>
                          </div>
                        </div>
                        <div className={pricingStyle.textMe}>
                          <h4>Price of Land at Tiwa Garden City</h4>
                          <table>
                            <tbody>
                              <tr>
                                <td>500sqm</td>
                                <td>N27,000,000</td>
                              </tr>
                              <tr>
                                <td>417sqm</td>
                                <td>N23,000,000</td>
                              </tr>
                              <tr>
                                <td>300sqm</td>
                                <td>N17,000,000</td>
                              </tr>
                              <tr>
                                <td>250sqm</td>
                                <td>N13,000,000</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div
                      className={pricingStyle.holdList}
                      style={{ marginTop: "20px" }}
                    >
                      <h3>Price Increase Projection (1st June 2023)</h3>
                      <div className={pricingStyle.holdContest}>
                        <div className={pricingStyle.textMe}>
                          <h4>Price of Land at Tiwa Garden City</h4>
                          <table>
                            <tbody>
                              <tr>
                                <td>500sqm</td>
                                <td>35,000,000</td>
                              </tr>
                              <tr>
                                <td>417sqm</td>
                                <td>30,000,000</td>
                              </tr>
                              <tr>
                                <td>300sqm</td>
                                <td>N23,000,000</td>
                              </tr>
                              <tr>
                                <td>250sqm</td>
                                <td>N17,000,000</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className={pricingStyle.image}>
                          <div className={pricingStyle.imageKokos}>
                            <div className={pricingStyle.imageKoko}>
                              <img
                                src={logo}
                                alt="Landing"
                                className={pricingStyle.logo}
                              />
                              <h3>1st June 2023</h3>
                              <p>Road and Drainage</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className={switchs === 2 ? pricingStyle.display : pricingStyle.notDisplay}> */}
                    <div
                      className={pricingStyle.holdList}
                      style={{ marginTop: "20px" }}
                    >
                      <h3>Price Increase Projection (1st October 2023)</h3>
                      <div className={pricingStyle.holdContest}>
                        <div className={pricingStyle.image}>
                          <div className={pricingStyle.imageKokos}>
                            <div className={pricingStyle.imageKoko}>
                              <img
                                src={logo}
                                alt="Landing"
                                className={pricingStyle.logo}
                              />
                              <h3>1st October 2023</h3>
                              <p>Electrification and Plantation</p>
                            </div>
                          </div>
                        </div>
                        <div className={pricingStyle.textMe}>
                          <h4>Price of Land at Tiwa Garden City</h4>
                          <table>
                            <tbody>
                              <tr>
                                <td>500sqm</td>
                                <td>50,000,000</td>
                              </tr>
                              <tr>
                                <td>417sqm</td>
                                <td>45,000,000</td>
                              </tr>
                              <tr>
                                <td>300sqm</td>
                                <td>N30,000,000</td>
                              </tr>
                              <tr>
                                <td>250sqm</td>
                                <td>N25,000,000</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ marginTop: "70px" }}>
                <h3 className={pricingStyle.OurPricing}>Our Payment Plans</h3>
                <p className={pricingStyle.check}>
                  Choose The Best <span>Payment Plans</span>
                  <br /> That Suits You.
                </p>
              </div>
              <div className={pricingStyle.holdAllPriceLand1}>
                <div className={pricingStyle.holdAllPriceLand2}>
                  <div className={pricingStyle.holdButin}>
                    <button
                      type="button"
                      className={
                        switchs2 === 0
                          ? pricingStyle.meter
                          : pricingStyle.outMeter
                      }
                      onClick={() => {
                        clickMe2(0);
                      }}
                    >
                      500 SQM
                    </button>
                    <button
                      type="button"
                      className={
                        switchs2 === 1
                          ? pricingStyle.meter
                          : pricingStyle.outMeter
                      }
                      onClick={() => {
                        clickMe2(1);
                      }}
                    >
                      417 SQM
                    </button>
                    <button
                      type="button"
                      className={
                        switchs2 === 2
                          ? pricingStyle.meter
                          : pricingStyle.outMeter
                      }
                      onClick={() => {
                        clickMe2(2);
                      }}
                    >
                      300 SQM
                    </button>
                    <button
                      type="button"
                      className={
                        switchs2 === 3
                          ? pricingStyle.meter
                          : pricingStyle.outMeter
                      }
                      onClick={() => {
                        clickMe2(3);
                      }}
                    >
                      250 SQM
                    </button>
                  </div>
                  <div
                    className={
                      switchs2 === 0
                        ? pricingStyle.display + pricingStyle.holdListMe2
                        : pricingStyle.notDisplay
                    }
                    style={{ width: "100%" }}
                  >
                    <div className={pricingStyle.holdTableMeNow}>
                      <table>
                        <thead>
                          <tr>
                            <th>Months</th>
                            <th>Amount</th>
                            <th>Initial Deposit</th>
                            <th>Monthly Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>0 - 3 month</td>
                            <td>N27,000,000</td>
                            <td>N2,000,000</td>
                            <td>N8,333,333</td>
                          </tr>
                          <tr>
                            <td>0 - 4 month</td>
                            <td>N28,000,000</td>
                            <td>N2,000,000</td>
                            <td>N4,333,333</td>
                          </tr>
                          <tr>
                            <td>7 - 12 month</td>
                            <td>N29,000,000</td>
                            <td>N2,000,000</td>
                            <td>N2,250,000</td>
                          </tr>
                          <tr>
                            <td>13 - 18 month</td>
                            <td>N30,000,000</td>
                            <td>N2,000,000</td>
                            <td>N1,555,555</td>
                          </tr>
                          <tr></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className={
                      switchs2 === 1
                        ? pricingStyle.display + pricingStyle.holdListMe2
                        : pricingStyle.notDisplay
                    }
                    style={{ width: "100%" }}
                  >
                    <div className={pricingStyle.holdTableMeNow}>
                      <table>
                        <thead>
                          <tr>
                            <th>Months</th>
                            <th>Amount</th>
                            <th>Initial Deposit</th>
                            <th>Monthly Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>0 - 3 month</td>
                            <td>N23,000,000</td>
                            <td>N2,000,000</td>
                            <td>N7,000,000</td>
                          </tr>
                          <tr>
                            <td>0 - 4 month</td>
                            <td>N24,000,000</td>
                            <td>N2,000,000</td>
                            <td>N3,666,666</td>
                          </tr>
                          <tr>
                            <td>7 - 12 month</td>
                            <td>N25,000,000</td>
                            <td>N2,000,000</td>
                            <td>N1,916,666</td>
                          </tr>
                          <tr>
                            <td>13 - 18 month</td>
                            <td>N26,500,000</td>
                            <td>N2,000,000</td>
                            <td>N1,333,333</td>
                          </tr>
                          <tr></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className={
                      switchs2 === 2
                        ? pricingStyle.display + pricingStyle.holdListMe2
                        : pricingStyle.notDisplay
                    }
                    style={{ width: "100%" }}
                  >
                    <div className={pricingStyle.holdTableMeNow}>
                      <table>
                        <thead>
                          <tr>
                            <th>Months</th>
                            <th>Amount</th>
                            <th>Initial Deposit</th>
                            <th>Monthly Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>0 - 3 month</td>
                            <td>N17,000,000</td>
                            <td>N2,000,000</td>
                            <td>N5,000,000</td>
                          </tr>
                          <tr>
                            <td>0 - 4 month</td>
                            <td>N18,000,000</td>
                            <td>N2,000,000</td>
                            <td>N2,666,666</td>
                          </tr>
                          <tr>
                            <td>7 - 12 month</td>
                            <td>N19,000,000</td>
                            <td>N2,000,000</td>
                            <td>N1,416,666</td>
                          </tr>
                          <tr>
                            <td>13 - 18 month</td>
                            <td>N20,000,000</td>
                            <td>N2,000,000</td>
                            <td>N1,000,000</td>
                          </tr>
                          <tr></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className={
                      switchs2 === 3
                        ? pricingStyle.display + pricingStyle.holdListMe2
                        : pricingStyle.notDisplay
                    }
                    style={{ width: "100%" }}
                  >
                    <div className={pricingStyle.holdTableMeNow}>
                      <table>
                        <thead>
                          <tr>
                            <th>Months</th>
                            <th>Amount</th>
                            <th>Initial Deposit</th>
                            <th>Monthly Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>0 - 3 month</td>
                            <td>N13,500,000</td>
                            <td>N2,000,000</td>
                            <td>N3,833,333</td>
                          </tr>
                          <tr>
                            <td>0 - 4 month</td>
                            <td>N14,500,000</td>
                            <td>N2,000,000</td>
                            <td>N2,083,333</td>
                          </tr>
                          <tr>
                            <td>7 - 12 month</td>
                            <td>N15,500,000</td>
                            <td>N2,000,000</td>
                            <td>N1,125,000</td>
                          </tr>
                          <tr>
                            <td>13 - 18 month</td>
                            <td>N16,500,000</td>
                            <td>N2,000,000</td>
                            <td>N805,555</td>
                          </tr>
                          <tr></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <p>
                    <button
                      onClick={handleShow1}
                      className={pricingStyle.buyNow}
                    >
                      Make Your Reservation
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <FooterPage />
        </div>
        <div>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h5>WANT TO BECOME A LANDLORD TODAY?</h5>
              </Modal.Title>
            </Modal.Header>
            <div className={pricingStyle.lineNowMe}>
              <div></div>
            </div>
            <Modal.Body>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Full Name"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Phone Number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Coupon Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Coupon Code"
                    value={cupomCode}
                    onChange={(e) => setCupomCode(e.target.value)}
                  />
                  <Form.Text className={`${pricingStyle.wrong}`}>
                    {notCoupon}
                  </Form.Text>
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </Form.Group>

                <button
                  type="submit"
                  className={pricingStyle.buyNow}
                  style={{ width: "100%" }}
                >
                  {load ? "Loading..." : "Submit"}
                </button>
              </Form>
            </Modal.Body>
          </Modal>

          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4>Make Your Reservation</h4>
              </Modal.Title>
            </Modal.Header>
            <div className={pricingStyle.lineNowMe}>
              <div></div>
            </div>
            <Modal.Body>
              <Form onSubmit={handleSubmit2}>
                <Form.Group className="mb-3" controlId="formGridAddress2">
                  <Form.Control
                    placeholder="Your Full Name"
                    style={{ padding: "15px" }}
                    value={fullName2}
                    onChange={(e) => setFullName2(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGridEmail">
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    style={{ padding: "15px" }}
                    value={email2}
                    onChange={(e) => setEmail2(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGridAddress1">
                  <Form.Control
                    placeholder="Enter Phone"
                    style={{ padding: "15px" }}
                    type="phone"
                    value={phone2}
                    onChange={(e) => setPhone2(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Select
                  aria-label="Default select example"
                  className="mb-3"
                  style={{ padding: "15px" }}
                  value={aboutUs}
                  onChange={(e) => setAboutUs(e.target.value)}
                  required
                >
                  <option value="">How did you hear about us?</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Billboard">Billboard</option>
                  <option value="Instagram"> Instagram</option>
                  <option value="Twitter">Twitter</option>
                  <option value="Google">Google</option>
                  <option value="LinkedIn">LinkedIn</option>
                  <option value="Referred by someone">
                    Referred by someone
                  </option>
                </Form.Select>

                <Form.Select
                  aria-label="Default select example"
                  className="mb-3"
                  style={{ padding: "15px" }}
                  value={broker}
                  onChange={(e) => setBroker(e.target.value)}
                  required
                >
                  <option value="">Are you a realtor or broker?</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Select>

                <Form.Select
                  aria-label="Default select example"
                  className="mb-3"
                  style={{ padding: "15px" }}
                  value={realtor}
                  onChange={(e) => setRealtor(e.target.value)}
                  required
                >
                  <option value="">Are you working with a realtor?</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Select>
                <button type="submit" className={pricingStyle.buyNow}>
                  {load ? "Loading..." : "Submit"}
                </button>
              </Form>
            </Modal.Body>
          </Modal>
        </div>
        <a
          href="https://wa.me/2347061032706"
          className={pricingStyle.whatsapp_float}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaWhatsapp className={pricingStyle.whatsappIcon} />
        </a>
      </section>
    );
}

export default Pricing;