import React, { useEffect, useState } from 'react';
import { GiPositionMarker } from 'react-icons/gi';
import { FaPhoneAlt } from 'react-icons/fa';
import { BiEnvelope } from 'react-icons/bi';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import FooterPage from '../footer/footer';
import ContactStyle from "./Contact.module.scss";
import logo from "../../Images/logo.jpg";
import { Form } from 'react-bootstrap';
import { db } from "../../firebase-config"
import { collection, addDoc } from "firebase/firestore";
import { HiMenu } from 'react-icons/hi';
import { FaFacebookF, FaInstagram, FaYoutube, FaWhatsapp } from 'react-icons/fa';

function Contact(props) {
    const [menu, setMenu] = useState(false);
    const [load, setLoad] = useState(false);
    const [modalShow1, setModalShow1] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [fullName, setFullName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [date, setDate] = useState("");
    const [category, setCategory] = useState("");
    const navigate = useNavigate()
    const contactCollectionRef = collection(db, "contact");

    useEffect(() => {
        const ifameData = document.getElementById("iframeId")
        const lat = 6.5435106;
        const lon = 3.9170862;
        ifameData.src = `https://maps.google.com/maps?q=${lat},${lon}&hl=es;&output=embed`
    })

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const menuMe = () => {
        setMenu(!menu);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    })

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoad(true);
        addDoc(contactCollectionRef, {
            fullName: fullName,
            phone: phone,
            email: email,
            date: date,
            category: category,
            read: false,
        })
            .then(() => {
                setLoad(false);
                setModalShow1(true);
                navigate("/success")
                setFullName("")
                setPhone("")
                setEmail("")
                setDate("")
                setCategory("")
            })
            .catch(error => {
                alert("Error occurred", error)
                setLoad(false);
            })
    }

    const isMobile = width <= 768;
    return (
      <section>
        <div>
          <div>
            <div className={ContactStyle.holdNav}>
              <div className={ContactStyle.holdNav}>
                <div className={ContactStyle.holdLogo}>
                  <img src={logo} alt="Landing" className={ContactStyle.logo} />
                </div>
                {isMobile ? (
                  <div className={ContactStyle.holdHandBurger}>
                    <span>
                      <a href="tel:+2347061032706" rel="noopener noreferrer">
                        <button className={ContactStyle.buyTiwaNow}>
                          Call Us Now !
                        </button>
                      </a>
                    </span>
                    <div className={ContactStyle.holdHi} onClick={menuMe}>
                      <HiMenu className={ContactStyle.hi} />
                    </div>
                  </div>
                ) : null}
                {isMobile ? (
                  <>
                    {menu ? (
                      <div className={ContactStyle.navLink}>
                        <span>
                          <NavLink
                            style={({ isActive }) => {
                              return isActive
                                ? {
                                    color: "#019785",
                                    textDecoration: "underline",
                                  }
                                : {};
                            }}
                            to={"/phase1"}
                          >
                            Home
                          </NavLink>
                        </span>
                        <span>
                          <NavLink
                            style={({ isActive }) => {
                              return isActive
                                ? {
                                    color: "#019785",
                                    textDecoration: "underline",
                                  }
                                : {};
                            }}
                            to={"/price"}
                          >
                            Pricing
                          </NavLink>
                        </span>
                        <span>
                          <NavLink
                            style={({ isActive }) => {
                              return isActive
                                ? {
                                    color: "#019785",
                                    textDecoration: "underline",
                                  }
                                : {};
                            }}
                            to={"/gallery"}
                          >
                            Gallery
                          </NavLink>
                        </span>
                        <span>
                          <NavLink
                            style={({ isActive }) => {
                              return isActive
                                ? {
                                    color: "#019785",
                                    textDecoration: "underline",
                                  }
                                : {};
                            }}
                            to={"/about"}
                          >
                            About
                          </NavLink>
                        </span>
                        <span>
                          <NavLink
                            style={({ isActive }) => {
                              return isActive
                                ? {
                                    color: "#019785",
                                    textDecoration: "underline",
                                  }
                                : {};
                            }}
                            to={"/contact"}
                          >
                            Contact
                          </NavLink>
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <div className={ContactStyle.navLink}>
                    <span>
                      <NavLink
                        style={({ isActive }) => {
                          return isActive
                            ? { color: "#019785", textDecoration: "underline" }
                            : {};
                        }}
                        to={"/phase1"}
                      >
                        Home
                      </NavLink>
                    </span>
                    <span>
                      <NavLink
                        style={({ isActive }) => {
                          return isActive
                            ? { color: "#019785", textDecoration: "underline" }
                            : {};
                        }}
                        to={"/price"}
                      >
                        Pricing
                      </NavLink>
                    </span>
                    <span>
                      <NavLink
                        style={({ isActive }) => {
                          return isActive
                            ? { color: "#019785", textDecoration: "underline" }
                            : {};
                        }}
                        to={"/gallery"}
                      >
                        Gallery
                      </NavLink>
                    </span>
                    <span>
                      <NavLink
                        style={({ isActive }) => {
                          return isActive
                            ? { color: "#019785", textDecoration: "underline" }
                            : {};
                        }}
                        to={"/about"}
                      >
                        About
                      </NavLink>
                    </span>
                    <span>
                      <NavLink
                        style={({ isActive }) => {
                          return isActive
                            ? { color: "#019785", textDecoration: "underline" }
                            : {};
                        }}
                        to={"/contact"}
                      >
                        Contact
                      </NavLink>
                    </span>
                    <span>
                      <a href="tel:+2347061032706" rel="noopener noreferrer">
                        <button className={ContactStyle.buyTiwaNow}>
                          Call Us Now!
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={ContactStyle.holdAboutContent1}>
            <div>
              <div style={{ marginTop: "90px" }}>
                <h3 className={ContactStyle.OurPricing}>
                  Schedule An Inspection
                </h3>
                <p className={ContactStyle.check}>
                  Let Us Help you Secure{" "}
                  <span style={{ color: "#019785" }}>Tiwa Garden City</span>
                  <br /> <span>The Biggest Entertainment City.</span>
                </p>
              </div>
              <div className={ContactStyle.holdAboutContent1}>
                <div className={ContactStyle.holdAboutContent}>
                  <div className={ContactStyle.holdImage}>
                    <div className={ContactStyle.topSend}>
                      <h5>Fill Form The Below</h5>
                      <BiEnvelope className={ContactStyle.iconsMe} />
                    </div>
                    <Form onSubmit={handleSubmit}>
                      <Form.Group className="mb-3" controlId="Yourname">
                        <Form.Label style={{ color: "#9F9F9F" }}>
                          Your name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                          required
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="PhoneNo">
                        <Form.Label style={{ color: "#9F9F9F" }}>
                          Phone No
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Phone No"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          required
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{ color: "#9F9F9F" }}>
                          Email
                        </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{ color: "#9F9F9F" }}>
                          Date of inspection
                        </Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="Date of inspection"
                          value={date}
                          onChange={(e) => setDate(e.target.value)}
                          required
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{ color: "#9F9F9F" }}>
                          Select your category
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          className="mb-3"
                          value={category}
                          onChange={(e) => setCategory(e.target.value)}
                          required
                        >
                          <option></option>
                          <option value="Client">Client</option>
                          <option value="Realtor or Associate">
                            Realtor or Associate
                          </option>
                          <option value="Realtor with client">
                            Realtor with client
                          </option>
                        </Form.Select>
                      </Form.Group>
                      <button type="submit" className={ContactStyle.button}>
                        {load ? "Loading..." : "Submit"}
                      </button>
                    </Form>
                  </div>
                  <div className={ContactStyle.holdText}>
                    <h4 className={ContactStyle.ContactInformation}>
                      Contact Information
                    </h4>
                    <p style={{ display: "flex" }}>
                      <GiPositionMarker
                        className={ContactStyle.icon}
                        style={{ fontSize: "35px" }}
                      />{" "}
                      <span style={{ display: "block" }}>
                        31 Theophilus Oji Street, Lekki Phase 1 100001,
                        Lekki, Lagos
                      </span>
                    </p>
                    <p>
                      <FaPhoneAlt
                        className={ContactStyle.icon}
                        style={{ fontSize: "17px" }}
                      />
                      0706 103 2706
                    </p>
                    <p>
                      <BiEnvelope
                        className={ContactStyle.icon}
                        style={{ fontSize: "23px" }}
                      />{" "}
                      info@tiwagardencity.com{" "}
                    </p>
                    <div className={ContactStyle.holdIcons}>
                      <a
                        href="https://www.facebook.com/TiwaGardenCity/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className={ContactStyle.hIcons}>
                          <FaFacebookF />
                        </div>
                      </a>
                      <a
                        href="https://www.instagram.com/tiwagardencity/"
                        target="_blank"
                        rel="noreferrer"
                        style={{ margin: "0 10px" }}
                      >
                        <div className={ContactStyle.hIcons}>
                          <FaInstagram />
                        </div>
                      </a>
                      <a
                        href="https://www.instagram.com/tiwagardencity/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className={ContactStyle.hIcons}>
                          <FaYoutube />
                        </div>
                      </a>
                    </div>
                    <div className={ContactStyle.mapInner}>
                      <iframe
                        id="iframeId"
                        className={ContactStyle.mapItself}
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <FooterPage />
          </div>
        </div>
        <a
          href="https://wa.me/2347061032706"
          className={ContactStyle.whatsapp_float}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaWhatsapp className={ContactStyle.whatsappIcon} />
        </a>
      </section>
    );
}

export default Contact;