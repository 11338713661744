import { useState } from "react";
import Style from "./menu.module.scss";
import Logo from "../../Images/logo2Now.png";
import { FiMenu } from "react-icons/fi";

export default function Menu() {
  const [menu, setMenu] = useState(false);

  const toogle = () => {
    setMenu(!menu);
  };
  return (
    <section data-aos="fade-up" data-aos-duration="3000">
      <div className={Style.holdAll}>
        <div className={Style.holdLogo}>
          <img src={Logo} alt="Logo" />
        </div>

        <div className={Style.holdMenu}>
          <div>
            <a
              href="https://wa.me/2347061032706"
              target="_blank"
              rel="noopener noreferrer"
              className={Style.call}
            >
              Call us
            </a>
          </div>
          <FiMenu onClick={toogle} />
        </div>

        <div className={menu ? Style.holdMenuList : Style.thisNot}>
          <ul>
            <li>
              <a href="#price">Pricing</a>
            </li>
            <li>
              <a href="#secure">FAQ</a>
            </li>
            <li>
              <a href="#secure">Contact</a>
            </li>
            <li>
              <a
                href="tel:+2347061032706"
                rel="noopener noreferrer"
                className={Style.call}
              >
                Call us
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
