import React, { useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import FooterPage from '../footer/footer';
import AboutStyle from "./About.module.scss";
import logo from "../../Images/TIWAlogo.svg";
import grammy1 from "../../Images/grammy1.png";
import grammy2 from "../../Images/grammy2.png";
import hand1 from "../../Images/hand1.png";
import who2 from "../../Images/who2.png";
import who1 from "../../Images/who1.png";
import bulb from "../../Images/bulb.png";
import memeber from "../../Images/memeber.png";
import TM3 from "../../Images/TM3.jpg";
import TM4 from "../../Images/TM4.jpg";
import TM5 from "../../Images/TM5.jpg";
import TM6 from "../../Images/TM6.jpg";
import scope from "../../Images/scope.png";
import { HiMenu } from 'react-icons/hi';
import { FaWhatsapp } from 'react-icons/fa';
import { MdKeyboardArrowRight } from 'react-icons/md';

function About(props) {
    const [menu, setMenu] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const menuMe = () => {
        setMenu(!menu);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    })

    const isMobile = width <= 768;
    return (
      <section>
        <div
          style={{
            backgroundColor: "#eeeeee",
            position: "absolute",
            zIndex: "-1",
          }}
        >
          <div>
            <div className={AboutStyle.holdNav}>
              <div className={AboutStyle.holdNav}>
                <div className={AboutStyle.holdLogo}>
                  <img src={logo} alt="Landing" className={AboutStyle.logo} />
                </div>
                {isMobile ? (
                  <div className={AboutStyle.holdHandBurger}>
                    <span>
                      <a href="tel:+2347061032706" rel="noopener noreferrer">
                        <button className={AboutStyle.buyTiwaNow}>
                          Call Us Now !
                        </button>
                      </a>
                    </span>
                    <div className={AboutStyle.holdHi} onClick={menuMe}>
                      <HiMenu className={AboutStyle.hi} />
                    </div>
                  </div>
                ) : null}
                {isMobile ? (
                  <>
                    {menu ? (
                      <div className={AboutStyle.navLink}>
                        <span>
                          <NavLink
                            style={({ isActive }) => {
                              return isActive
                                ? {
                                    color: "#019785",
                                    textDecoration: "underline",
                                  }
                                : {};
                            }}
                            to={"/phase1"}
                          >
                            Home
                          </NavLink>
                        </span>
                        <span>
                          <NavLink
                            style={({ isActive }) => {
                              return isActive
                                ? {
                                    color: "#019785",
                                    textDecoration: "underline",
                                  }
                                : {};
                            }}
                            to={"/price"}
                          >
                            Pricing
                          </NavLink>
                        </span>
                        <span>
                          <NavLink
                            style={({ isActive }) => {
                              return isActive
                                ? {
                                    color: "#019785",
                                    textDecoration: "underline",
                                  }
                                : {};
                            }}
                            to={"/gallery"}
                          >
                            Gallery
                          </NavLink>
                        </span>
                        <span>
                          <NavLink
                            style={({ isActive }) => {
                              return isActive
                                ? {
                                    color: "#019785",
                                    textDecoration: "underline",
                                  }
                                : {};
                            }}
                            to={"/about"}
                          >
                            About
                          </NavLink>
                        </span>
                        <span>
                          <NavLink
                            style={({ isActive }) => {
                              return isActive
                                ? {
                                    color: "#019785",
                                    textDecoration: "underline",
                                  }
                                : {};
                            }}
                            to={"/contact"}
                          >
                            Contact
                          </NavLink>
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <div className={AboutStyle.navLink}>
                    <span>
                      <NavLink
                        style={({ isActive }) => {
                          return isActive
                            ? { color: "#019785", textDecoration: "underline" }
                            : {};
                        }}
                        to={"/phase1"}
                      >
                        Home
                      </NavLink>
                    </span>
                    <span>
                      <NavLink
                        style={({ isActive }) => {
                          return isActive
                            ? { color: "#019785", textDecoration: "underline" }
                            : {};
                        }}
                        to={"/price"}
                      >
                        Pricing
                      </NavLink>
                    </span>
                    <span>
                      <NavLink
                        style={({ isActive }) => {
                          return isActive
                            ? { color: "#019785", textDecoration: "underline" }
                            : {};
                        }}
                        to={"/gallery"}
                      >
                        Gallery
                      </NavLink>
                    </span>
                    <span>
                      <NavLink
                        style={({ isActive }) => {
                          return isActive
                            ? { color: "#019785", textDecoration: "underline" }
                            : {};
                        }}
                        to={"/about"}
                      >
                        About
                      </NavLink>
                    </span>
                    <span>
                      <NavLink
                        style={({ isActive }) => {
                          return isActive
                            ? { color: "#019785", textDecoration: "underline" }
                            : {};
                        }}
                        to={"/contact"}
                      >
                        Contact
                      </NavLink>
                    </span>
                    <span>
                      <a href="tel:+2347061032706" rel="noopener noreferrer">
                        <button className={AboutStyle.buyTiwaNow}>
                          Call Us Now!
                        </button>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <div className={AboutStyle.holdContent}>
            <div className={AboutStyle.holdTopText}>
              <h4 className={AboutStyle.ValueServices}>
                Value | Services | Innovation
              </h4>
              <div className={AboutStyle.holdLine}>
                <div></div>
              </div>
              <p className={AboutStyle.weAim}>
                We aim at delivering the best entertainment city with all
                available resources that will make the living easy.
              </p>
            </div>
            <div></div>
          </div>

          <div className={AboutStyle.holdAllValues}>
            <div className={AboutStyle.allValues}>
              <div className={AboutStyle.allValues2}>
                <div className={AboutStyle.holdValuesImage}>
                  <img
                    src={grammy1}
                    className={AboutStyle.valuesImage1}
                    alt="Manna"
                  />
                </div>
                <div className={AboutStyle.valuesText1}>
                  <div className={AboutStyle.hand1Koko}>
                    <img src={hand1} alt="Manna" />
                  </div>
                  <h4 className={AboutStyle.valueTetx}>VALUE</h4>
                  <p className={AboutStyle.driving}>
                    The driving force behind Tiwa Garden City is to provide
                    value and resources, with world class amenities that you
                    will always value.
                  </p>
                  <a href="#aboutUs" style={{ textDecoration: "none" }}>
                    <p className={AboutStyle.See}>See more about us</p>
                  </a>
                </div>
              </div>
              <div className={AboutStyle.allValues2}>
                <div className={AboutStyle.thrive}>
                  <div className={AboutStyle.hand2}>
                    <img src={hand1} alt="Manna" />
                  </div>
                  <p className={AboutStyle.we}>
                    We thrive to deliver the best services and support to make
                    you enjoy the best entertainment based city in africa.
                  </p>
                  <a href="#aboutUs" style={{ textDecoration: "none" }}>
                    <button type="button" className={AboutStyle.more}>
                      See more about us <MdKeyboardArrowRight />
                    </button>
                  </a>
                </div>
                <div>
                  <div className={AboutStyle.holdValuesImage}>
                    <img
                      src={grammy2}
                      className={AboutStyle.valuesImage1}
                      alt="Manna"
                    />
                  </div>
                  <div className={AboutStyle.valuesText1}>
                    <div className={AboutStyle.hand1Koko}>
                      <img src={bulb} alt="Manna" />
                    </div>
                    <h4 className={AboutStyle.valueTetx}>Innovation</h4>
                    <p className={AboutStyle.driving}>
                      The goal of Tiwa Garden city on the long run is to make to
                      imple- ment enough innovative ideas and make it the tech
                      hub of the nation
                    </p>
                    <a
                      href="#aboutUs"
                      id="aboutUs"
                      style={{ textDecoration: "none" }}
                    >
                      <p className={AboutStyle.See}>See more about us</p>
                    </a>
                  </div>
                </div>
                {/* <div>
                                <div>
                                    <img src={grammy2} alt="Manna" />
                                </div>
                                <div>
                                    <div>
                                        <img src={bulb} alt="Manna" />
                                    </div>
                                    <h4>VALUE</h4>
                                    <p>
                                        The driving force behind Tiwa Garden
                                        City is to provide value and resources,
                                        with world class amenities that you
                                        will always value.
                                    </p>
                                    <p>See more about us</p>
                                </div>
                            </div> */}
              </div>
            </div>
          </div>
          <div className={AboutStyle.holdWhoWeAre} id="aboutUs">
            <div className={AboutStyle.holdWhoWeAre2}>
              <div className={AboutStyle.whoWeAre1}>
                <h4>Who We Are</h4>
                <div className={AboutStyle.holdLine2}>
                  <div></div>
                </div>
                <p className={AboutStyle.cutting}>
                  Zylus Homes is a cutting-edge real estate and investment
                  company, dedicated to increasing access to homes and property
                  ownership in Africa. Our research has made us realize that
                  buying and selling real estate is presumed to have so many
                  tasking procedures.
                  <br />
                  <br />
                  Hence, this is the rationale behind keeping our approach as
                  simple and straightforward as possible. In this regard, we
                  empower people to make smarter decisions concerning buying,
                  developing their home or investing in our properties.
                  <br />
                  <br />
                  We have, over the years, built credibility and are known for
                  offering excellent service delivery. Our testimonials and
                  success stories have been featured in reputable newspapers
                  such as Punch, Vanguard, Business Day, among many others. We
                  exist to put a smile on our customers’ faces!
                  <br />
                </p>
              </div>
              <div className={AboutStyle.whoWeAre2}>
                <div>
                  <img
                    src={who1}
                    className={AboutStyle.vassel1}
                    alt="working well"
                  />
                </div>
                <div>
                  <img
                    src={who2}
                    className={AboutStyle.vassel2}
                    alt="working well"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={AboutStyle.holdBoxMe1}>
            <div className={AboutStyle.holdBoxMe2}>
              <div className={AboutStyle.boxNow}>
                <h5 style={{ borderBottom: "2px solid #ff9201" }}>Mission</h5>
                <p>
                  To deliver real estate investment opportunities to our
                  esteemed clients both in Nigeria and abroad through our well
                  structured plan.
                </p>
              </div>
              <div className={AboutStyle.boxNow2}>
                <h5 style={{ borderBottom: "2px solid #019785" }}>Vision</h5>
                <p>
                  We exist as an entity to make every individual a property
                  owner
                </p>
              </div>
            </div>
          </div>

          <div className={AboutStyle.holdBoxMe3}>
            <div className={AboutStyle.boxNow3}>
              <h5 style={{ borderBottom: "2px solid #019785" }}>
                Our Core Values
              </h5>
              <p>Integrity</p>
              <p>Excellent Service Delivery</p>
              <p>Passion and professionalism</p>
              <p>Competency and Dynamism</p>
              <p>Creativity and Innovation</p>
            </div>
          </div>

          <div>
            <h4 style={{ textAlign: "center", marginTop: "70px" }}>
              Meet Our Team
            </h4>
            <div className={AboutStyle.holdLine}>
              <div></div>
            </div>
            <div className={AboutStyle.holdTeamImg}>
              <div className={AboutStyle.holdTeamImg2}>
                <div className={AboutStyle.holdhtmnKOKO}>
                  <img
                    src={memeber}
                    alt="Working"
                    className={AboutStyle.htmnKOKO}
                  />
                </div>
                <div className={AboutStyle.holdhtmnKOKO}>
                  <img
                    src={memeber}
                    alt="Working"
                    className={AboutStyle.htmnKOKO}
                  />
                </div>
                <div className={AboutStyle.holdhtmnKOKO}>
                  <img
                    src={TM3}
                    alt="Working"
                    className={AboutStyle.htmnKOKO}
                  />
                </div>
                <div className={AboutStyle.holdhtmnKOKO}>
                  <img
                    src={TM4}
                    alt="Working"
                    className={AboutStyle.htmnKOKO}
                  />
                </div>
                <div className={AboutStyle.holdhtmnKOKO}>
                  <img
                    src={TM5}
                    alt="Working"
                    className={AboutStyle.htmnKOKO}
                  />
                </div>
                <div className={AboutStyle.holdhtmnKOKO}>
                  <img
                    src={TM6}
                    alt="Working"
                    className={AboutStyle.htmnKOKO}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={AboutStyle.holdScope}>
            <div className={AboutStyle.holdScope2}>
              <div className={AboutStyle.holdScopeText}>
                <h3>Explore more about Tiwa </h3>
                <p>
                  Tiwa Garden City has a wide range of services and value to
                  offer. Take a look atsome of the innovative things we are
                  bringing to Tiwa Garden City in grand style.
                </p>
                <Link to={"/"}>
                  <button type="buttom">EXPLORE</button>
                </Link>
              </div>
              <div className={AboutStyle.holdScopeImage}>
                <img src={scope} alt="Scope" />
              </div>
            </div>
          </div>
          <div className={AboutStyle.holdSubcscribe}>
            <div className={AboutStyle.holdSubcscribe2}>
              <p className={AboutStyle.holdTextScr2}>
                Subscribe To Our Newsletter To Get Updates And Stay Connected
              </p>
              <div>
                <div>
                  <input
                    type="email"
                    placeholder="Email"
                    className={AboutStyle.holdInputEmail}
                  />
                  <button type="submit" className={AboutStyle.subButinNow}>
                    Subcribe
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className={AboutStyle.footMintColor}>
            <div></div>
          </div>
          <br />
          <div>
            <FooterPage />
          </div>
        </div>
        <a
          href="https://wa.me/2347061032706"
          className={AboutStyle.whatsapp_float}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaWhatsapp className={AboutStyle.whatsappIcon} />
        </a>
      </section>
    );
}

export default About;