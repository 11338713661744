import React from 'react';

function Overview() {
    return (
        <section>
            <div>
                <h1>Overview</h1>
            </div>
        </section>
    );
}

export default Overview;