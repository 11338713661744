import ZYLUSNEWLogoFooter from "../../Images/ZYLUS NEW Logo 1.png";
import { Link } from "react-router-dom";
import { ImFacebook, ImYoutube } from "react-icons/im";
import { AiOutlineInstagram } from "react-icons/ai";
import FooterStyle from "./Footer.module.scss";
import TiwaLogo from "../../Images/logo2Now.png";

function FooterPhase2() {
  return (
    <section>
      <div className={FooterStyle.holdAll}>
        <div className={FooterStyle.Location}>
          <img src={TiwaLogo} alt="Arizonlogo" />
          <h5>Our Location</h5>
          <p>
            Head Office: 4A Omon Ebhomenye Street, Off Admiralty Way, Lekki Phase 1, Lagos,
            Nigeria.
            <br />
            Mainland Cooperate Office: 65, Allen Avenue, First Bank Bus-stop,
            Ikeja, Lagos, Nigeria.
          </p>
        </div>
        <div className={FooterStyle.AboutTiwa}>
          <ul>
            <h5>About Zylus Homes</h5>
            <li>
            <a href="https://blog.zylushomes.com/" target="_blank">Real Estate Videos</a>
            </li>
            <li>
              <a href="https://blog.zylushomes.com/" target="_blank">Blog</a>
            </li>
            <li>
              <a href="#secure">Book an Inspection</a>
            </li>
            <li>
            <a href="https://blog.zylushomes.com/" target="_blank">How To Use Zylus Portal</a>
            </li>
            <li>
            <a href="#secure">Purchase</a>
            </li>
          </ul>
        </div>
        <div className={FooterStyle.Support}>
          <ul>
            <h5>Help & Support</h5>
            <li>
            <a href="#secure">FAQS</a>
            </li>
            <li>
            <a
              href="https://wa.me/2347061032706"
              target="_blank"
              rel="noopener noreferrer"
            >
              Customer Care
            </a>
            </li>
            <li>
              <a href="#secure">Book an Inspection</a>
            </li>
            <li>
            <a href="https://blog.zylushomes.com/" target="_blank">How To Use Zylus Portal</a>
            </li>
            <li>
              <a href="https://blog.zylushomes.com/" target="_blank">News</a>
            </li>
          </ul>
        </div>
        <div className={FooterStyle.Support1}>
          <h5>Follow Us</h5>
          <div>
            <a
              href="https://www.facebook.com/groups/1107458949860758/"
              target="_blank"
              rel="noreferrer"
            >
              <div>
                <ImFacebook />
              </div>
            </a>
            <a
              href="https://www.instagram.com/zylushomes/"
              target="_blank"
              rel="noreferrer"
              style={{ margin: "0 10px" }}
            >
              <div>
                <AiOutlineInstagram />
              </div>
            </a>
            <a
              href="https://www.youtube.com/@zylustv6590"
              target="_blank"
              rel="noreferrer"
            >
              <div>
                <ImYoutube />
              </div>
            </a>
          </div>
          <h4 className={FooterStyle.PoweredBy}>Powered By</h4>
          <a href="https://www.zylushomes.com/" target="_blank" rel="noopener noreferrer">
          <img
            src={ZYLUSNEWLogoFooter}
            alt="ZYLUSNEWLogoFooter"
            className={FooterStyle.ZYLUSNEWLogo}
          />
          </a>
        </div>
      </div>
    </section>
  );
}

export default FooterPhase2;
