import './App.scss';
import './global.scss';
import Home from './Components/Home/Home';
import Contact from './Components/Contact/Contact';
import About from './Components/About/About';
import Pricing from './Components/pricing/pricing';
import Login from './Components/Admin/Login/Login';
import { Routes, Route } from 'react-router-dom';
import Index from './Components/Admin';
import MakeEnquiry from './Components/Admin/MakeEnquiry/MakeEnquiry';
import Reservation from './Components/Admin/Reservation/Reservation';
import AdminContact from './Components/Admin/Contact/Contact';
import Overview from './Components/Admin/overview/overview';
import { NotFound } from './Components/NotFound/NotFound';
import { useSelector } from "react-redux"
import SucceesModal from './Components/modal/modalSuccess';
import Gallery from './Components/Gallery/Gallery';
import ReservationHub from './Components/Admin/ReservationHub/Layout/Layout1';
import LandingPage from "./Components/LandingPage/LandingPage";
import Home2 from "./Components/Home2/Home";
import Phase2 from "./Components/Admin/Phase2/Phase2";

function App() {
  const user = useSelector((state) => state.login);
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/phase1" element={<Home />} />
      <Route path="/phase2" element={<Home2 />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/about" element={<About />} />
      <Route path="/price" element={<Pricing />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/login" element={<Login />} />
      <Route path="/success" element={<SucceesModal />} />
      {user.count ? (
        <Route path="admin" element={<Index />}>
          <Route path="tiwaphase2">
            <Route index={true} element={<Phase2 />} />
          </Route>

          <Route path="overview">
            <Route index={true} element={<Overview />} />
          </Route>

          <Route path="make-enquiry">
            <Route index={true} element={<MakeEnquiry />} />
          </Route>

          <Route path="reservation">
            <Route index={true} element={<Reservation />} />
          </Route>

          <Route path="reservationhub">
            <Route index={true} element={<ReservationHub />} />
          </Route>

          <Route path="contact">
            <Route index={true} element={<AdminContact />} />
          </Route>
        </Route>
      ) : (
        <Route path="*" element={<NotFound />} />
      )}
    </Routes>
  );
}

export default App;
