// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCE3tL8dLyYgzlIqKJdS72MnoupzMLZ4js",
    authDomain: "zylus-tiwa-garden-city.firebaseapp.com",
    projectId: "zylus-tiwa-garden-city",
    storageBucket: "zylus-tiwa-garden-city.appspot.com",
    messagingSenderId: "877223512799",
    appId: "1:877223512799:web:d453b9b2759b8acaf5776a",
    measurementId: "G-G2HCE51WB0"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
