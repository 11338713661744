/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable indent */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */

import { Button, Modal } from 'react-bootstrap';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import successStyle from './modalSuccess.module.scss';
import { useNavigate } from 'react-router-dom';

function SucceesModal(props) {
    const navigate = useNavigate();
    const handleClose = () => {
        navigate(-1)
    }
    return (
        <Modal
            show={true}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body style={{ display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <div>
                    <AiOutlineCheckCircle className={successStyle.close} />
                </div>
                <p className={successStyle.successText}>Successful</p>

                <Button type="button" className={successStyle.closeButin} onClick={handleClose}>
                    Close
                </Button>
            </Modal.Body>
        </Modal>
    );
}

export default SucceesModal;
