import React, { useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import GalleryStyle from "./Gallery.module.scss";
import { HiMenu } from 'react-icons/hi';
import { FaGreaterThan, FaLessThan } from 'react-icons/fa';
import logo from "../../Images/TIWAlogo.svg";
import heart from "../../Images/heart.png";
import share from "../../Images/share.png";
import phone from "../../Images/phone.png";
import sms from "../../Images/sms.png";
import landGallery2 from "../../Images/landGallery2.png";
import landGallery3 from "../../Images/landGallery3.png";
import landGallery1 from "../../Images/landGallery1.png";
import landGallery4 from "../../Images/landGallery4.png";
import TESTIMONIAL5n from "../../Images/TESTIMONIAL 5n.mp4";
import TESTIMONIAL6n from "../../Images/TESTIMONIALS 4.mp4";
import TESTIMONIAL7n from "../../Images/TESTIMONIAL 6.mp4";
import FooterPage from '../footer/footer';
import { motion } from "framer-motion"

function Gallery(props) {
    const [width, setWidth] = useState(window.innerWidth);
    const [menu, setMenu] = useState(false);
    const [currentV, setCurrentV] = useState(0);
    const isMobile = width <= 768;

    const changeVideoIncrease = () => {
        setCurrentV(function (prevCount) {
            if (prevCount < 2) {
                return (prevCount += 1);
            } else {
                return (prevCount = 0);
            }
        })
    }

    const changeVideoDecrease = () => {
        setCurrentV(function (prevCount) {
            if (prevCount > 0) {
                return (prevCount -= 1);
            } else {
                return (prevCount = 2);
            }
        })
    }

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const menuMe = () => {
        setMenu(!menu);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    })
    return (
        <section>
            <div className={GalleryStyle.holdNav}>
                <div className={GalleryStyle.holdLogo}>
                    <img src={logo} alt="Landing" className={GalleryStyle.logo} />
                </div>
                {isMobile ?
                    <div className={GalleryStyle.holdHandBurger}>
                        <span>
                            <a
                                href="tel:+2347061032706"
                                rel="noopener noreferrer"
                            >
                                <button className={GalleryStyle.buyTiwaNow}>Call Us Now !</button>
                            </a>
                        </span>
                        <div className={GalleryStyle.holdHi} onClick={menuMe}>
                            <HiMenu className={GalleryStyle.hi} />
                        </div>
                    </div> : null}
                {isMobile ? <>{menu ? <div className={GalleryStyle.navLink}>
                    <span>
                        <NavLink
                            style={({ isActive }) => {
                                return isActive ? { color: "#019785", textDecoration: "underline" } : {}
                            }} to={"/phase1"}
                        >
                            Home
                        </NavLink>
                    </span>
                    <span>
                        <NavLink style={({ isActive }) => {
                            return isActive ? { color: "#019785", textDecoration: "underline" } : {}
                        }} to={"/price"}
                        >
                            Pricing
                        </NavLink>
                    </span>
                    <span>
                        <NavLink style={({ isActive }) => {
                            return isActive ? { color: "#019785", textDecoration: "underline" } : {}
                        }} to={"/gallery"}
                        >
                            Gallery
                        </NavLink>
                    </span>
                    <span>
                        <NavLink style={({ isActive }) => {
                            return isActive ? { color: "#019785", textDecoration: "underline" } : {}
                        }} to={"/about"}
                        >
                            About
                        </NavLink>
                    </span>
                    <span>
                        <NavLink
                            style={({ isActive }) => {
                                return isActive ? { color: "#019785", textDecoration: "underline" } : {}
                            }} to={"/contact"}
                        >
                            Contact
                        </NavLink>
                    </span>
                    <span>
                        <a
                            href="tel:+2347061032706"
                            rel="noopener noreferrer"
                        >
                            <button className={GalleryStyle.buyTiwaNow}>Call Us Now!</button>
                        </a>
                    </span>
                </div> : ""}</> : <div className={GalleryStyle.navLink}>
                    <span>
                        <NavLink
                            style={({ isActive }) => {
                                return isActive ? { color: "#019785", textDecoration: "underline" } : {}
                            }} to={"/phase1"}
                        >
                            Home
                        </NavLink>
                    </span>
                    <span>
                        <NavLink style={({ isActive }) => {
                            return isActive ? { color: "#019785", textDecoration: "underline" } : {}
                        }} to={"/price"}
                        >
                            Pricing
                        </NavLink>
                    </span>
                    <span>
                        <NavLink style={({ isActive }) => {
                            return isActive ? { color: "#019785", textDecoration: "underline" } : {}
                        }} to={"/gallery"}
                        >
                            Gallery
                        </NavLink>
                    </span>
                    <span>
                        <NavLink style={({ isActive }) => {
                            return isActive ? { color: "#019785", textDecoration: "underline" } : {}
                        }} to={"/about"}
                        >
                            About
                        </NavLink>
                    </span>
                    <span>
                        <NavLink
                            style={({ isActive }) => {
                                return isActive ? { color: "#019785", textDecoration: "underline" } : {}
                            }} to={"/contact"}
                        >
                            Contact
                        </NavLink>
                    </span>
                    <span>
                        <a
                            href="tel:+2347061032706"
                            rel="noopener noreferrer"
                        >
                            <button className={GalleryStyle.buyTiwaNow}>Call Us Now!</button>
                        </a>
                    </span>
                </div>}
            </div>
            <div className={GalleryStyle.holdGalleryHead}>
                <div className={GalleryStyle.holdGalleryHead2}>
                    <h4>Gallery</h4>
                    <div className={GalleryStyle.holdLine}>
                        <div></div>
                    </div>
                    <p>We put together the best ideas to implement the develop the best homes for you.</p>
                </div>
            </div>
            <div className={GalleryStyle.holdShare}>
                <div className={GalleryStyle.holdShare1}>
                    <img src={heart} alt="heart" className={GalleryStyle.heart} />
                    <img src={share} alt="share" className={GalleryStyle.share} />
                </div>
            </div>
            <div className={GalleryStyle.holdVideo}>
                <div className={GalleryStyle.greater} onClick={changeVideoDecrease}>
                    <FaLessThan />
                </div>
                <div className={GalleryStyle.holdVideo1}>
                    {currentV === 0 ? <motion.video
                        src={TESTIMONIAL5n}
                        controls
                        className={GalleryStyle.videos}
                        animate={{ x: 0, scale: 1, rotate: 360, }}
                        initial={{ scale: 0 }}
                    /> : null}
                    {currentV === 1 ? <motion.video
                        src={TESTIMONIAL6n}
                        controls
                        className={GalleryStyle.videos}
                        animate={{ x: 0, scale: 1, rotate: 360, }}
                        initial={{ scale: 0 }} /> : null}
                    {currentV === 2 ? <motion.video
                        src={TESTIMONIAL7n}
                        controls
                        className={GalleryStyle.videos}
                        animate={{ x: 0, scale: 1, rotate: 360, }}
                        initial={{ scale: 0 }}
                    /> : null}
                </div>
                <div className={GalleryStyle.greater2} onClick={changeVideoIncrease}>
                    <FaGreaterThan />
                </div>
            </div>
            <div className={GalleryStyle.GenerousOjakovo}>
                <div className={GalleryStyle.GenerousOjakovo2}>
                    <div className={GalleryStyle.holdPhoto}>
                        <div className={GalleryStyle.holdLine2}>
                            <div></div>
                        </div>
                        <p>Photos</p>
                    </div>
                    <div className={GalleryStyle.holdLandGallery1}>
                        <div className={GalleryStyle.gallery1LayOne}>
                            <img src={landGallery1} alt={landGallery1} className={GalleryStyle.landGallery1Image} />
                            <div className={GalleryStyle.nature}>
                                <button className={GalleryStyle.exploring}>Exploring Nature</button>
                                <p className={GalleryStyle.breath}>
                                    Every Breath We Take, Every Step We Make, Can Be
                                    Filled With Peace, Joy And Serenity.
                                    Tiwa Is Here
                                </p>
                            </div>
                            <div className={GalleryStyle.landGallery1}>
                                <div className={GalleryStyle.landGallery2}>
                                    <div className={GalleryStyle.smallImage}>
                                        <img src={landGallery4} alt={landGallery4} className={GalleryStyle.landGallery4Image} />
                                        <div>
                                            <h5 className={GalleryStyle.generous}>Generous O.</h5>
                                            <p className={GalleryStyle.generous2}>Customer Service</p>
                                        </div>
                                    </div>
                                    <div>
                                        <img src={phone} alt={phone} className={GalleryStyle.landGallery4Image} />
                                        <img src={sms} alt={sms} className={GalleryStyle.landGallery4Image} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={GalleryStyle.gallery1LayOne2}>
                            <img src={landGallery2} alt={landGallery2} className={GalleryStyle.landGallery2Image} />
                            <img src={landGallery3} alt={landGallery3} className={GalleryStyle.landGallery3Image} />
                        </div>
                    </div>

                    <div className={GalleryStyle.holdLandGallery2}>
                        <div className={GalleryStyle.gallery1LayOne}>
                            <img src={landGallery1} alt={landGallery1} className={GalleryStyle.landGallery1ImageRe} />
                            <div className={GalleryStyle.natures}>
                                <button className={GalleryStyle.exploring}>Exploring Nature</button>
                                <p className={GalleryStyle.breath}>
                                    Every Breath We Take, Every Step We Make, Can Be
                                    Filled With Peace, Joy And Serenity.
                                    Tiwa Is Here
                                </p>
                            </div>
                            <div className={GalleryStyle.landGallery1s}>
                                <div className={GalleryStyle.landGallery2}>
                                    <div className={GalleryStyle.smallImage}>
                                        <img src={landGallery4} alt={landGallery4} className={GalleryStyle.landGallery4Image} />
                                        <div>
                                            <h5 className={GalleryStyle.generous}>Generous O.</h5>
                                            <p className={GalleryStyle.generous2}>Customer Service</p>
                                        </div>
                                    </div>
                                    <div>
                                        <img src={phone} alt={phone} className={GalleryStyle.landGallery4Image} />
                                        <img src={sms} alt={sms} className={GalleryStyle.landGallery4Image} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={GalleryStyle.gallery1LayOne2}>
                            <img src={landGallery2} alt={landGallery2} className={GalleryStyle.landGallery2Image} />
                            <img src={landGallery3} alt={landGallery3} className={GalleryStyle.landGallery3Image} />
                        </div>
                    </div>

                    <div className={GalleryStyle.holdLandGallery1}>
                        <div className={GalleryStyle.gallery1LayOne}>
                            <img src={landGallery1} alt={landGallery1} className={GalleryStyle.landGallery1Image} />
                            <div className={GalleryStyle.natureRe3}>
                                <button className={GalleryStyle.exploring}>Exploring Nature</button>
                                <p className={GalleryStyle.breath}>
                                    Every Breath We Take, Every Step We Make, Can Be
                                    Filled With Peace, Joy And Serenity.
                                    Tiwa Is Here
                                </p>
                            </div>
                            <div className={`${GalleryStyle.landGallery1Rs3} ${GalleryStyle.landGallery1Rs3Pro}`}>
                                <div className={GalleryStyle.landGallery2}>
                                    <div className={GalleryStyle.smallImage}>
                                        <img src={landGallery4} alt={landGallery4} className={GalleryStyle.landGallery4Image} />
                                        <div>
                                            <h5 className={GalleryStyle.generous}>Generous O.</h5>
                                            <p className={GalleryStyle.generous2}>Customer Service</p>
                                        </div>
                                    </div>
                                    <div className={GalleryStyle.openNewTab}>
                                        <a href="tel:+2347061032706">
                                            <img src={phone} alt={phone} className={GalleryStyle.landGallery4Image} />
                                        </a>
                                        <img src={sms} alt={sms} className={GalleryStyle.landGallery4Image} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={GalleryStyle.gallery1LayOne2}>
                            <img src={landGallery2} alt={landGallery2} className={GalleryStyle.landGallery2Image} />
                            <img src={landGallery3} alt={landGallery3} className={GalleryStyle.landGallery3Image} />
                        </div>
                    </div>
                </div>
            </div>

            <div className={GalleryStyle.Testimonials}>
                <div className={GalleryStyle.Testimonials2}>
                    <div></div>
                    <div>
                        <div className={GalleryStyle.holdPhoto}>
                            <div className={GalleryStyle.holdLine2}>
                                <div></div>
                            </div>
                            <p>Testimonials</p>
                        </div>
                        <div className={GalleryStyle.videosAndText}>
                            <div className={GalleryStyle.videosT}>
                                <video src={TESTIMONIAL5n} controls className={GalleryStyle.TestimonialsVideos} />
                            </div>
                            <div className={GalleryStyle.videosT1}>
                                <h4>Investing with zylus changed everything</h4>
                                <p>
                                    Tiwa Garden City is different and wears a crown of
                                    distinction, style, luxury and class. Every Breath We
                                    Take,  Every Step We Make, Can Be Filled With Peace,
                                    Joy And Serenity.
                                </p>
                                <Link to="/price">
                                    <button >SEE PRICING</button>
                                </Link>
                            </div>
                        </div>
                        <div className={`${GalleryStyle.videosAndText} ${GalleryStyle.videosAndTextWe}`}>
                            <div className={GalleryStyle.videosT1}>
                                <h4>Investing with zylus changed everything</h4>
                                <p>
                                    Tiwa Garden City is different and wears a crown of
                                    distinction, style, luxury and class. Every Breath We
                                    Take,  Every Step We Make, Can Be Filled With Peace,
                                    Joy And Serenity.
                                </p>
                                <Link to="/price">
                                    <button >SEE PRICING</button>
                                </Link>
                            </div>
                            <div className={GalleryStyle.videosT}>
                                <video src={TESTIMONIAL6n} controls className={GalleryStyle.TestimonialsVideos} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={GalleryStyle.holdSubcscribe}>
                <div className={GalleryStyle.holdSubcscribe2}>
                    <p className={GalleryStyle.holdTextScr2}>Subscribe To Our Newsletter To Get Updates And Stay Connected</p>
                    <div>
                        <div>
                            <input type="email" placeholder="Email" className={GalleryStyle.holdInputEmail} />
                            <button type="submit" className={GalleryStyle.subButinNow}>Subcribe</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={GalleryStyle.footMintColor}>
                <div></div>
            </div>
            <br />
            <a
                href="tel:+2347061032706"
                className={GalleryStyle.call1}
                rel="noopener noreferrer"
            >
            </a>
            <a
                href="mailto:Tolatoyinbo@zylusgroup.com"
                className={GalleryStyle.msg1}
                rel="noopener noreferrer"
            >
            </a>

            <a
                href="tel:+2347061032706"
                className={GalleryStyle.call2}
                rel="noopener noreferrer"
            >
            </a>
            <a
                href="mailto:Tolatoyinbo@zylusgroup.com"
                className={GalleryStyle.msg2}
                rel="noopener noreferrer"
            >
            </a>

            <a
                href="tel:+2347061032706"
                className={GalleryStyle.call3}
                rel="noopener noreferrer"
            >
            </a>
            <a
                href="mailto:Tolatoyinbo@zylusgroup.com"
                className={GalleryStyle.msg3}
                rel="noopener noreferrer"
            >
            </a>
            <div>
                <FooterPage />
            </div>
        </section>
    );
}

export default Gallery;