import React from "react";
import Logo from "../../Images/ZYLUS NEW Logo 1.png";
import za1 from "../../Images/za1.png";
import za2 from "../../Images/za2.png";
import click1 from "../../Images/click1.png";
import style from "./LandingPage.module.scss";
import { Link } from "react-router-dom";

export default function LandingPage() {
  return (
    <section>
      <div className={style.nav}>
        <a href="https://www.zylushomes.com/" target="_blank">
          <div>
            <span>Powered By</span>
            <img src={Logo} />
          </div>
        </a>
        <button>Support</button>
      </div>

      <div className={style.holdImage}>
        <Link to={"/phase1"} className={style.holdImage1}>
          <div>
            <p>Click here to enter Tiwa Garden City Phase 1</p>
            <img src={click1} alt="Not Available" />
          </div>
        </Link>
        <Link to={"/phase2"} className={style.holdImage1}>
          <div>
            <p>Click here to enter Tiwa Garden City Phase 2</p>
            <img src={click1} alt="Not Available" />
          </div>
        </Link>
      </div>
    </section>
  );
}
