/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import Style from "./Home.module.scss";
import FooterPhase2 from "../FooterPhase2/FooterPhase2";
import FooterPage from "../footer/footer";
import Menu from "../Menu/menu";
import hero2 from "../../Images/hero2.png";
import { Link } from "react-router-dom";
import pic1 from "../../Images/pic1.png";
import pic2 from "../../Images/pic2.png";
import pic3 from "../../Images/pic3.png";
import pic4 from "../../Images/pic4.png";
import pic5 from "../../Images/pic5.png";
import videopic from "../../Images/new arizon project.png";
import owner1 from "../../Images/owner 1.png";
import owner2 from "../../Images/owner 2.png";
import owner3 from "../../Images/owner 3.png";
import house1 from "../../Images/house1.png";
import house2 from "../../Images/house2.png";
import house3 from "../../Images/house3.png";
import house4 from "../../Images/house4.png";
import house5 from "../../Images/house5.png";
import house6 from "../../Images/house6.png";
import landmarl1 from "../../Images/landmarl1.png";
import landmarl2 from "../../Images/landmarl2.png";
import landmarl3 from "../../Images/landmarl3.png";
import landmarl4 from "../../Images/landmarl4.png";
import landmarl5 from "../../Images/landmarl5.png";
import landmarl6 from "../../Images/landmarl6.png";
import test1 from "../../Images/test1.png";
import test2 from "../../Images/test2.png";
import test3 from "../../Images/test3.png";
import test4 from "../../Images/test4.png";
import team1 from "../../Images/team1.png";
import team2 from "../../Images/team2.png";
import team3 from "../../Images/team3.png";
import team4 from "../../Images/team4.png";
import team5 from "../../Images/team5.png";
import mail1 from "../../Images/mail1.png";
import mail2 from "../../Images/mail2.png";
import mail3 from "../../Images/mail3.png";
import mail4 from "../../Images/mail4.png";
import mail5 from "../../Images/mail5.png";
import mail6 from "../../Images/mail6.png";
import rating from "../../Images/rating.png";
import FAQS from "../../Images/TIWA PHASE 2FORM AND FAQ NEW.pdf";
import { IoCall, IoCloseSharp } from "react-icons/io5";
import { IoLocationSharp } from "react-icons/io5";
import { IoDocumentText } from "react-icons/io5";
import { IoReceiptSharp } from "react-icons/io5";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase-config";
import { useNavigate } from "react-router-dom";

function Home2() {
  const [loading, setLoading] = useState(false);
  const [video2, setVideo2] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [purchase, setPurchase] = useState("");
  const [realtor, setRealtor] = useState("");
  const [hear, setHear] = useState("");
  const [soon, setSoon] = useState("");
  const [country, setCountry] = useState("");

  const [price, setPrice] = useState(0);
  const [num, setNum] = useState(0);

  const phase2CollectionRef2 = collection(db, "phase2");
  const current = new Date();
  const navigate = useNavigate();

  const countButin2 = (index) => {
    setPrice(index);
    setNum(0);
  };

  const handleSubmit2 = (e) => {
    e.preventDefault();
    setLoading2(true)
    addDoc(phase2CollectionRef2, {
      Name: fullName,
      Email: email,
      Phone: phone,
      Purchase: purchase,
      realtor: realtor,
      hear: hear,
      soon: soon,
      country: country,
      date: `${current.getDate()}/${
        current.getMonth() + 1
      }/${current.getFullYear()}`,
      read: false,
    })
      .then(() => {
        setFullName("");
        setEmail("");
        setPhone("");
        setPurchase("");
        setRealtor("");
        setHear("");
        setSoon("");
        setLoading2(false);
        navigate("/success");
      })
      .catch(() => {
        setLoading2(false);
        alert("Error");
      });
  };

  const videoButin2 = () => {
    setVideo2(!video2);
  };
  return (
    <section>
      <Menu />
      <div className={Style.holdImage}>
        <img src={hero2} alt="Hero" />

        <div className={Style.hodWelcome2}>
          <h1>
            Elevate Your <br /> Living Experience
          </h1>
          <p>Your Gateway to Elevated Landed Living</p>
          <Link to={""}>Get Started</Link>
        </div>
      </div>

      <div className={Style.friend}>
        <img src={pic1} alt="pic1" />
        <img src={pic2} alt="pic2" />
        <img src={pic3} alt="pic3" />
        <img src={pic4} alt="pic4" />
        <img src={pic5} alt="pic5" />
      </div>

      <div className={Style.holdWatchArizon}>
        <div className={Style.holdWatchArizon2}>
          <h1>Take a Peak into</h1>
          <h1>Your Dream Property </h1>
          <div className={Style.holdEstateFeaturesRedLine}>
            <div></div>
          </div>
          <div className={Style.holdWatchVideo} onClick={videoButin2}>
            <img src={videopic} alt="videopic" />
          </div>
          <a href="#form1">
            <button>Secure Your Plot</button>
          </a>
        </div>
      </div>

      <div className={Style.holdShould}>
        <div className={Style.holdShould2}>
          <div className={Style.holdShould3}>
            <h1 className={Style.Arizon}>
              Top Reasons to Invest in Tiwa Garden City II
            </h1>
            <div className={Style.holdArizon}>
              <div></div>
            </div>
            <div className={Style.holdHomeownwers}>
              <div className={Style.holdHomeownwers2}>
                <div>
                  <img src={owner1} alt="owner1" className={Style.holdOwner1} />
                </div>
                <div>
                  <h4>For Homeownwers</h4>
                  <p>
                    Tiwa Garden Phase II Estate is strategically located in an
                    area with promising growth prospects, as indicated by the
                    presence of landmarks like the Dangote Refinery, New
                    Airport, and Deep Sea Port.
                  </p>
                  <p>
                    Homeowners can benefit from potential appreciation in
                    property values, allowing them to build equity over time.
                    Additionally, investing in a property within a promising
                    area can offer a sense of security and stability.
                  </p>
                </div>
              </div>
              <div className={Style.holdHomeownwers2}>
                <div>
                  <img src={owner2} alt="owner1" className={Style.holdOwner1} />
                </div>
                <div>
                  <h4>For Investors</h4>
                  <p>
                    The presence of key landmarks and indicators of growth
                    suggests that property values in Tiwa Garden City Phase 2
                    may appreciate over time. As the surrounding area develops
                    further, demand for property in this location could
                    increase, leading to higher resale values. Investors can
                    generate rental income by leasing out properties in Tiwa
                    Garden City Phase 2.
                  </p>
                  <p>
                    With the area's promising growth prospects, there may be a
                    demand for residential or commercial rental properties from
                    individuals, families, or businesses seeking to establish
                    themselves in the area
                  </p>
                </div>
              </div>
              <div className={Style.holdHomeownwers2}>
                <div>
                  <img src={owner3} alt="owner1" className={Style.holdOwner1} />
                </div>
                <div>
                  <h4>For Businesses</h4>
                  <p>
                    With the area's promising growth prospects, there will be a
                    demand for residential or commercial rental properties from
                    individuals, families, or businesses seeking to establish
                    themselves in the area
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className={Style.holdFrom} id="form1">
            <h6 className={Style.fillOut}>
              Fill out the form and a member of the team would reach out to you
              and break down your land acquisition process.
            </h6>
            <div className={Style.holdFrom2}>
              <h1 className={Style.holdFrom2H1}>
                SECURE YOUR PIECE OF TIWA GARDEN CITY II
              </h1>
              <div className={Style.holdRedLine}>
                <div></div>
              </div>
              <form onSubmit={handleSubmit2} className={Style.holdForm3}>
                <input
                  type="text"
                  placeholder="Name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  required
                />

                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />

                <input
                  type="number"
                  placeholder="Phone Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />

                <input
                  type="text"
                  placeholder="Country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  required
                />

                <select
                  value={realtor}
                  onChange={(e) => setRealtor(e.target.value)}
                  required
                >
                  <option value="">What are you interested in ?</option>
                  <option value="500 SQM">500 SQM</option>
                  <option value="300 SQM">300 SQM</option>
                  <option value="Other">Other</option>
                </select>

                <select
                  value={purchase}
                  onChange={(e) => setPurchase(e.target.value)}
                  required
                >
                  <option value="">How many plot do you want to buy ?</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>

                {/* <input
                      type="number"
                      value={}
                      onChange={(e) => (e.target.value)}
                      placeholder="How many plot do you want to buy ?"
                      required
                    /> */}

                <select
                  value={soon}
                  onChange={(e) => setSoon(e.target.value)}
                  required
                >
                  <option value="">
                    How soon did you want to purchase your land ?
                  </option>
                  <option value="Immediately">Immediately</option>
                  <option value="Within a week">Within a week</option>
                  <option value="Within a month">Within a month</option>
                </select>

                <select
                  value={hear}
                  onChange={(e) => setHear(e.target.value)}
                  required
                >
                  <option value="">How did you hear about us ?</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Billboard">Billboard</option>
                  <option value="Instagram"> Instagram</option>
                  <option value="Twitter">Twitter</option>
                  <option value="Google">Google</option>
                  <option value="LinkedIn">LinkedIn</option>
                  <option value="Referred by someone">
                    Referred by someone
                  </option>
                </select>

                <div className={Style.holdCheckBox}>
                  <input type="checkbox" required />
                  <p>
                    I consent to have this website store my submitted
                    information so they can respond to my inquiry.
                  </p>
                </div>

                <button type="submit">
                  {loading2 ? "Please wait..." : "Secure Your Plot Today"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className={Style.holdTiwaGarden}>
        <div className={Style.holdTiwaGarden2}>
          <h1 className={Style.GardenTiwa}>
            Tiwa Garden City II Estate Features
          </h1>
          <div className={Style.holdEstateFeaturesRedLine}>
            <div></div>
          </div>

          <div className={Style.holdBox}>
            <div className={Style.box1}>
              <h3>Estate Mall</h3>
              <p>
                The presence of a supermarket within the estate allows for
                convenient grocery shopping.
              </p>
              <img src={house1} alt="Not Available" />
              <a href="#form1">
                <button>Secure Now {">>>"}</button>
              </a>
            </div>

            <div className={Style.box2}>
              <h3>Mini Park</h3>
              <p>
                Experience the Ease of Grocery Shopping with the Presence of a
                Supermarket Within the Estate.
              </p>
              <img src={house2} alt="Not Available" />
              <a href="#form1">
                <button>Secure Now {">>>"}</button>
              </a>
            </div>

            <div className={Style.box3}>
              <h3>Sport Center</h3>
              <p>
                Enjoy Access to a State-of-the-Art Sports Center Right Within
                the Estate.
              </p>
              <img src={house3} alt="Not Available" />
              <a href="#form1">
                <button>Secure Now {">>>"}</button>
              </a>
            </div>

            <div className={Style.box4}>
              <h3>Street Light</h3>
              <p>
                Benefit from Enhanced Safety and Security with Street Lights
                Throughout the Estate.
              </p>
              <img src={house4} alt="Not Available" />
              <a href="#form1">
                <button>Secure Now {">>>"}</button>
              </a>
            </div>

            <div className={Style.box5}>
              <h3>Gym/Fitness Center</h3>
              <p>
                Discover the Ultimate Fitness Experience with Access to a
                Fully-Equipped Gym and Fitness Center Within the Estate
              </p>
              <img src={house5} alt="Not Available" />
              <a href="#form1">
                <button>Secure Now {">>>"}</button>
              </a>
            </div>

            <div className={Style.box6}>
              <h3>Spa Center</h3>
              <p>
                Rejuvenate Your Mind, Body, and Soul with the Luxurious Spa
                Center Conveniently Located Within the Estate
              </p>
              <img src={house6} alt="Not Available" />
              <a href="#form1">
                <button>Secure Now {">>>"}</button>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={Style.holdPricingAnd} id="price">
        <div className={Style.holdPricingAnd1}>
          <div className={Style.holdPricingAn2}>
            <h2>Pricing and Payment Plan</h2>
            <div className={Style.holdPricingRedLine}>
              <div></div>
            </div>
            <p>
              Our Pricing plans are designed to be affordable, flexible, and
              tailored to your unique capability
            </p>
          </div>

          <div className={Style.holdOutrightPayment}>
            <h1
              className={
                price === 0
                  ? Style.holdOutrightPayment1
                  : Style.holdOutrightPayment2
              }
              onClick={() => countButin2(0)}
            >
              Outright
              <br /> Payment
            </h1>

            <h1
              className={
                price === 1
                  ? Style.holdOutrightPayment1
                  : Style.holdOutrightPayment2
              }
              onClick={() => countButin2(1)}
            >
              Installment
              <br /> Payment
            </h1>
          </div>

          {price === 0 && (
            <div className={Style.MILLION}>
              <div className={Style.MILLION2}>
                <div className={Style.MILLION4}>
                  <h3>300 SQM</h3>
                  <p className={Style.MILLION5}>
                    {/* <IoLocationSharp /> */}
                    Key into Tiwa Garden City Phase II Pre launch price before
                    its deadline
                  </p>
                  <div className={Style.PreLaunch}>
                    <div>
                      <h4>Pre-Launch Price</h4>
                      <h1>18M</h1>
                    </div>
                  </div>
                  <p>
                    <IoDocumentText /> Government Allocation
                  </p>
                  <p>
                    <IoLocationSharp /> By Eleran Igbe Bus Stop
                  </p>
                  <p>
                    <IoReceiptSharp /> Statutory & infrastructure 3.6M
                  </p>
                  <a href="#secure">
                    <button>Secure Now</button>
                  </a>
                </div>
              </div>

              <div className={Style.MILLION2}>
                <div className={Style.MILLION4}>
                  <h3>500 SQM</h3>
                  <p className={Style.MILLION5}>
                    {/* <IoLocationSharp /> */}
                    Key into Tiwa Garden City Phase II Pre launch price before
                    its deadline
                  </p>
                  <div className={Style.PreLaunch}>
                    <div>
                      <h4>Pre-Launch Price</h4>
                      <h1>29M</h1>
                    </div>
                  </div>
                  <p>
                    <IoDocumentText /> Government Allocation
                  </p>
                  <p>
                    <IoLocationSharp /> By Eleran Igbe Bus Stop
                  </p>
                  <p>
                    <IoReceiptSharp /> Statutory & infrastructure 5M
                  </p>
                  <a href="#secure">
                    <button>Secure Now</button>
                  </a>
                </div>
              </div>

              <div className={Style.MILLION2}>
                <div className={Style.MILLION4}>
                  <h3>600 SQM</h3>
                  <p className={Style.MILLION5}>
                    {/* <IoLocationSharp /> */}
                    Key into Tiwa Garden City Phase II Pre launch price before
                    its deadline
                  </p>
                  <div className={Style.PreLaunch}>
                    <div>
                      <h4>Pre-Launch Price</h4>
                      <h1>36M</h1>
                    </div>
                  </div>
                  <p>
                    <IoDocumentText /> Government Allocation
                  </p>
                  <p>
                    <IoLocationSharp /> By Eleran Igbe Bus Stop
                  </p>
                  <p>
                    <IoReceiptSharp /> Statutory & infrastructure 5M
                  </p>
                  <a href="#secure">
                    <button>Secure Now</button>
                  </a>
                </div>
              </div>

              {/* <div className={Style.MILLION2}>
                    <img
                      src={ }
                      alt=" "
                      className={Style.MILLION2Img}
                    />
                    <div className={Style.MILLION4}>
                      <h3>Pre-Launch Price</h3>
                      <p className={Style.MILLION5}>
                         <IoLocationSharp/>
                        Eleko, Idera Scheme, Ibeju Lekki
                      </p>
                      <p className={Style.MILLION6}>Lagos State Allocation</p>
                      <div className={Style.MILLION7}>
                        <h2>0-6 MONTHS</h2>
                        <h2>N15 MILLION</h2>
                      </div>
                      <p className={Style.MILLION8}>
                        Initial Deposit: 2 MILLION
                      </p>
                      <p className={Style.MILLION9}>Pre Launch Price Valid</p>
                      <p className={Style.MILLION10}>Till 31st August, 2023</p>
                      <a href="#secure">
                        <button>Secure Now</button>
                      </a>
                    </div>
                  </div>

                  <div className={Style.MILLION2}>
                    <img
                      src={ }
                      alt=" "
                      className={Style.MILLION2Img}
                    />
                    <div className={Style.MILLION4}>
                      <h3>Pre-Launch Price</h3>
                      <p className={Style.MILLION5}>
                         <IoLocationSharp/>
                        Eleko, Idera Scheme, Ibeju Lekki
                      </p>
                      <p className={Style.MILLION6}>Lagos State Allocation</p>
                      <div className={Style.MILLION7}>
                        <h2>7-12 MONTHS</h2>
                        <h2>N17 MILLION</h2>
                      </div>
                      <p className={Style.MILLION8}>
                        Initial Deposit: 2 MILLION
                      </p>
                      <p className={Style.MILLION9}>Pre Launch Price Valid</p>
                      <p className={Style.MILLION10}>Till 31st August, 2023</p>
                      <a href="#secure">
                        <button>Secure Now</button>
                      </a>
                    </div>
                  </div>

                  <div className={Style.MILLION2}>
                    <img
                      src={ }
                      alt=" "
                      className={Style.MILLION2Img}
                    />
                    <div className={Style.MILLION4}>
                      <h3>Pre-Launch Price</h3>
                      <p className={Style.MILLION5}>
                         <IoLocationSharp/>
                        Eleko, Idera Scheme, Ibeju Lekki
                      </p>
                      <p className={Style.MILLION6}>Lagos State Allocation</p>
                      <div className={Style.MILLION7}>
                        <h2>7-12 MONTHS</h2>
                        <h2>N18 MILLION</h2>
                      </div>
                      <p className={Style.MILLION8}>
                        Initial Deposit: 2 MILLION
                      </p>
                      <p className={Style.MILLION9}>Pre Launch Price Valid</p>
                      <p className={Style.MILLION10}>Till 31st August, 2023</p>
                      <a href="#secure">
                        <button>Secure Now</button>
                      </a>
                    </div>
                  </div>

                  <div className={Style.MILLION2}>
                    <img
                      src={ }
                      alt=" "
                      className={Style.MILLION2Img}
                    />
                    <div className={Style.MILLION4}>
                      <h3>Pre-Launch Price</h3>
                      <p className={Style.MILLION5}>
                         <IoLocationSharp/>
                        Eleko, Idera Scheme, Ibeju Lekki
                      </p>
                      <p className={Style.MILLION6}>Lagos State Allocation</p>
                      <div className={Style.MILLION7}>
                        <h2>13-18 MONTHS</h2>
                        <h2>N19.5 MILLION</h2>
                      </div>
                      <p className={Style.MILLION8}>
                        Initial Deposit: 2 MILLION
                      </p>
                      <p className={Style.MILLION9}>Pre Launch Price Valid</p>
                      <p className={Style.MILLION10}>Till 31st August, 2023</p>
                      <a href="#secure">
                        <button>Secure Now</button>
                      </a>
                    </div>
                  </div> */}
            </div>
          )}

          {price === 1 && (
            <div>
              {num == 0 && (
                <div className={Style.holdPaymentPlan}>
                  <div className={Style.holdPaymentPlan2}>
                    <button
                      className={Style.hold300Plan}
                      onClick={() => setNum(1)}
                    >
                      300 SQM PAYMENT PLAN
                    </button>
                    <button
                      className={Style.hold300Plan2}
                      onClick={() => setNum(2)}
                    >
                      500 SQM PAYMENT PLAN
                    </button>
                    <button
                      className={Style.hold300Plan}
                      onClick={() => setNum(3)}
                    >
                      600 SQM PAYMENT PLAN
                    </button>
                  </div>
                </div>
              )}

              {num === 3 && (
                <div className={Style.MILLION}>
                  <div className={Style.MILLION2}>
                    <div className={Style.MILLION4}>
                      <h3>0-3 MONTHS</h3>
                      <p className={Style.MILLION5}>
                        {/* <IoLocationSharp /> */}
                        Key into Tiwa Garden City Phase II Pre launch price
                        before its deadline
                      </p>
                      <div className={Style.PreLaunch}>
                        <div>
                          <h4>Pre-Launch Price</h4>
                          <h1>36M</h1>
                        </div>
                      </div>
                      <p>
                        <IoDocumentText /> Government Allocation
                      </p>
                      <p>
                        <IoLocationSharp /> By Eleran Igbe Bus Stop
                      </p>
                      <p>
                        <IoReceiptSharp /> Statutory & infrastructure 5M
                      </p>
                      <a href="#secure">
                        <button>Secure Now</button>
                      </a>
                    </div>
                  </div>

                  <div className={Style.MILLION2}>
                    <div className={Style.MILLION4}>
                      <h3>4-6 MONTHS</h3>
                      <p className={Style.MILLION5}>
                        {/* <IoLocationSharp /> */}
                        Key into Tiwa Garden City Phase II Pre launch price
                        before its deadline
                      </p>
                      <div className={Style.PreLaunch}>
                        <div>
                          <h4>Pre-Launch Price</h4>
                          <h1>38M</h1>
                        </div>
                      </div>
                      <p>
                        <IoDocumentText /> Government Allocation
                      </p>
                      <p>
                        <IoLocationSharp /> By Eleran Igbe Bus Stop
                      </p>
                      <p>
                        <IoReceiptSharp /> Statutory & infrastructure 5M
                      </p>
                      <a href="#secure">
                        <button>Secure Now</button>
                      </a>
                    </div>
                  </div>

                  <div className={Style.MILLION2}>
                    <div className={Style.MILLION4}>
                      <h3>7-12 MONTH</h3>
                      <p className={Style.MILLION5}>
                        {/* <IoLocationSharp /> */}
                        Key into Tiwa Garden City Phase II Pre launch price
                        before its deadline
                      </p>
                      <div className={Style.PreLaunch}>
                        <div>
                          <h4>Pre-Launch Price</h4>
                          <h1>40M</h1>
                        </div>
                      </div>
                      <p>
                        <IoDocumentText /> Government Allocation
                      </p>
                      <p>
                        <IoLocationSharp /> By Eleran Igbe Bus Stop
                      </p>
                      <p>
                        <IoReceiptSharp /> Statutory & infrastructure 5M
                      </p>
                      <a href="#secure">
                        <button>Secure Now</button>
                      </a>
                    </div>
                  </div>

                  <div className={Style.MILLION2}>
                    <div className={Style.MILLION4}>
                      <h3>13-18 MONTH</h3>
                      <p className={Style.MILLION5}>
                        {/* <IoLocationSharp /> */}
                        Key into Tiwa Garden City Phase II Pre launch price
                        before its deadline
                      </p>
                      <div className={Style.PreLaunch}>
                        <div>
                          <h4>Pre-Launch Price</h4>
                          <h1>42M</h1>
                        </div>
                      </div>
                      <p>
                        <IoDocumentText /> Government Allocation
                      </p>
                      <p>
                        <IoLocationSharp /> By Eleran Igbe Bus Stop
                      </p>
                      <p>
                        <IoReceiptSharp /> Statutory & infrastructure 5M
                      </p>
                      <a href="#secure">
                        <button>Secure Now</button>
                      </a>
                    </div>
                  </div>

                  {/* <div className={Style.MILLION2}>
                    <img
                      src={ }
                      alt=" "
                      className={Style.MILLION2Img}
                    />
                    <div className={Style.MILLION4}>
                      <h3>Pre-Launch Price</h3>
                      <p className={Style.MILLION5}>
                         <IoLocationSharp/>
                        Eleko, Idera Scheme, Ibeju Lekki
                      </p>
                      <p className={Style.MILLION6}>Lagos State Allocation</p>
                      <div className={Style.MILLION7}>
                        <h2>0-6 MONTHS</h2>
                        <h2>N15 MILLION</h2>
                      </div>
                      <p className={Style.MILLION8}>
                        Initial Deposit: 2 MILLION
                      </p>
                      <p className={Style.MILLION9}>Pre Launch Price Valid</p>
                      <p className={Style.MILLION10}>Till 31st August, 2023</p>
                      <a href="#secure">
                        <button>Secure Now</button>
                      </a>
                    </div>
                  </div>

                  <div className={Style.MILLION2}>
                    <img
                      src={ }
                      alt=" "
                      className={Style.MILLION2Img}
                    />
                    <div className={Style.MILLION4}>
                      <h3>Pre-Launch Price</h3>
                      <p className={Style.MILLION5}>
                         <IoLocationSharp/>
                        Eleko, Idera Scheme, Ibeju Lekki
                      </p>
                      <p className={Style.MILLION6}>Lagos State Allocation</p>
                      <div className={Style.MILLION7}>
                        <h2>7-12 MONTHS</h2>
                        <h2>N17 MILLION</h2>
                      </div>
                      <p className={Style.MILLION8}>
                        Initial Deposit: 2 MILLION
                      </p>
                      <p className={Style.MILLION9}>Pre Launch Price Valid</p>
                      <p className={Style.MILLION10}>Till 31st August, 2023</p>
                      <a href="#secure">
                        <button>Secure Now</button>
                      </a>
                    </div>
                  </div>

                  <div className={Style.MILLION2}>
                    <img
                      src={ }
                      alt=" "
                      className={Style.MILLION2Img}
                    />
                    <div className={Style.MILLION4}>
                      <h3>Pre-Launch Price</h3>
                      <p className={Style.MILLION5}>
                         <IoLocationSharp/>
                        Eleko, Idera Scheme, Ibeju Lekki
                      </p>
                      <p className={Style.MILLION6}>Lagos State Allocation</p>
                      <div className={Style.MILLION7}>
                        <h2>7-12 MONTHS</h2>
                        <h2>N18 MILLION</h2>
                      </div>
                      <p className={Style.MILLION8}>
                        Initial Deposit: 2 MILLION
                      </p>
                      <p className={Style.MILLION9}>Pre Launch Price Valid</p>
                      <p className={Style.MILLION10}>Till 31st August, 2023</p>
                      <a href="#secure">
                        <button>Secure Now</button>
                      </a>
                    </div>
                  </div>

                  <div className={Style.MILLION2}>
                    <img
                      src={ }
                      alt=" "
                      className={Style.MILLION2Img}
                    />
                    <div className={Style.MILLION4}>
                      <h3>Pre-Launch Price</h3>
                      <p className={Style.MILLION5}>
                         <IoLocationSharp/>
                        Eleko, Idera Scheme, Ibeju Lekki
                      </p>
                      <p className={Style.MILLION6}>Lagos State Allocation</p>
                      <div className={Style.MILLION7}>
                        <h2>13-18 MONTHS</h2>
                        <h2>N19.5 MILLION</h2>
                      </div>
                      <p className={Style.MILLION8}>
                        Initial Deposit: 2 MILLION
                      </p>
                      <p className={Style.MILLION9}>Pre Launch Price Valid</p>
                      <p className={Style.MILLION10}>Till 31st August, 2023</p>
                      <a href="#secure">
                        <button>Secure Now</button>
                      </a>
                    </div>
                  </div> */}
                </div>
              )}

              {num === 2 && (
                <div className={Style.MILLION}>
                  <div className={Style.MILLION2}>
                    <div className={Style.MILLION4}>
                      <h3>0-3 MONTHS</h3>
                      <p className={Style.MILLION5}>
                        {/* <IoLocationSharp /> */}
                        Key into Tiwa Garden City Phase II Pre launch price
                        before its deadline
                      </p>
                      <div className={Style.PreLaunch}>
                        <div>
                          <h4>Pre-Launch Price</h4>
                          <h1>29M</h1>
                        </div>
                      </div>
                      <p>
                        <IoDocumentText /> Government Allocation
                      </p>
                      <p>
                        <IoLocationSharp /> By Eleran Igbe Bus Stop
                      </p>
                      <p>
                        <IoReceiptSharp /> Statutory & infrastructure 5M
                      </p>
                      <a href="#secure">
                        <button>Secure Now</button>
                      </a>
                    </div>
                  </div>

                  <div className={Style.MILLION2}>
                    <div className={Style.MILLION4}>
                      <h3>4-6 MONTHS</h3>
                      <p className={Style.MILLION5}>
                        {/* <IoLocationSharp /> */}
                        Key into Tiwa Garden City Phase II Pre launch price
                        before its deadline
                      </p>
                      <div className={Style.PreLaunch}>
                        <div>
                          <h4>Pre-Launch Price</h4>
                          <h1>31M</h1>
                        </div>
                      </div>
                      <p>
                        <IoDocumentText /> Government Allocation
                      </p>
                      <p>
                        <IoLocationSharp /> By Eleran Igbe Bus Stop
                      </p>
                      <p>
                        <IoReceiptSharp /> Statutory & infrastructure 5M
                      </p>
                      <a href="#secure">
                        <button>Secure Now</button>
                      </a>
                    </div>
                  </div>

                  <div className={Style.MILLION2}>
                    <div className={Style.MILLION4}>
                      <h3>7-12 MONTH</h3>
                      <p className={Style.MILLION5}>
                        {/* <IoLocationSharp /> */}
                        Key into Tiwa Garden City Phase II Pre launch price
                        before its deadline
                      </p>
                      <div className={Style.PreLaunch}>
                        <div>
                          <h4>Pre-Launch Price</h4>
                          <h1>33M</h1>
                        </div>
                      </div>
                      <p>
                        <IoDocumentText /> Government Allocation
                      </p>
                      <p>
                        <IoLocationSharp /> By Eleran Igbe Bus Stop
                      </p>
                      <p>
                        <IoReceiptSharp /> Statutory & infrastructure 5M
                      </p>
                      <a href="#secure">
                        <button>Secure Now</button>
                      </a>
                    </div>
                  </div>

                  <div className={Style.MILLION2}>
                    <div className={Style.MILLION4}>
                      <h3>13-18 MONTH</h3>
                      <p className={Style.MILLION5}>
                        {/* <IoLocationSharp /> */}
                        Key into Tiwa Garden City Phase II Pre launch price
                        before its deadline
                      </p>
                      <div className={Style.PreLaunch}>
                        <div>
                          <h4>Pre-Launch Price</h4>
                          <h1>35M</h1>
                        </div>
                      </div>
                      <p>
                        <IoDocumentText /> Government Allocation
                      </p>
                      <p>
                        <IoLocationSharp /> By Eleran Igbe Bus Stop
                      </p>
                      <p>
                        <IoReceiptSharp /> Statutory & infrastructure 5M
                      </p>
                      <a href="#secure">
                        <button>Secure Now</button>
                      </a>
                    </div>
                  </div>

                  {/* <div className={Style.MILLION2}>
                    <img
                      src={ }
                      alt=" "
                      className={Style.MILLION2Img}
                    />
                    <div className={Style.MILLION4}>
                      <h3>Pre-Launch Price</h3>
                      <p className={Style.MILLION5}>
                         <IoLocationSharp/>
                        Eleko, Idera Scheme, Ibeju Lekki
                      </p>
                      <p className={Style.MILLION6}>Lagos State Allocation</p>
                      <div className={Style.MILLION7}>
                        <h2>0-6 MONTHS</h2>
                        <h2>N15 MILLION</h2>
                      </div>
                      <p className={Style.MILLION8}>
                        Initial Deposit: 2 MILLION
                      </p>
                      <p className={Style.MILLION9}>Pre Launch Price Valid</p>
                      <p className={Style.MILLION10}>Till 31st August, 2023</p>
                      <a href="#secure">
                        <button>Secure Now</button>
                      </a>
                    </div>
                  </div>

                  <div className={Style.MILLION2}>
                    <img
                      src={ }
                      alt=" "
                      className={Style.MILLION2Img}
                    />
                    <div className={Style.MILLION4}>
                      <h3>Pre-Launch Price</h3>
                      <p className={Style.MILLION5}>
                         <IoLocationSharp/>
                        Eleko, Idera Scheme, Ibeju Lekki
                      </p>
                      <p className={Style.MILLION6}>Lagos State Allocation</p>
                      <div className={Style.MILLION7}>
                        <h2>7-12 MONTHS</h2>
                        <h2>N17 MILLION</h2>
                      </div>
                      <p className={Style.MILLION8}>
                        Initial Deposit: 2 MILLION
                      </p>
                      <p className={Style.MILLION9}>Pre Launch Price Valid</p>
                      <p className={Style.MILLION10}>Till 31st August, 2023</p>
                      <a href="#secure">
                        <button>Secure Now</button>
                      </a>
                    </div>
                  </div>

                  <div className={Style.MILLION2}>
                    <img
                      src={ }
                      alt=" "
                      className={Style.MILLION2Img}
                    />
                    <div className={Style.MILLION4}>
                      <h3>Pre-Launch Price</h3>
                      <p className={Style.MILLION5}>
                         <IoLocationSharp/>
                        Eleko, Idera Scheme, Ibeju Lekki
                      </p>
                      <p className={Style.MILLION6}>Lagos State Allocation</p>
                      <div className={Style.MILLION7}>
                        <h2>7-12 MONTHS</h2>
                        <h2>N18 MILLION</h2>
                      </div>
                      <p className={Style.MILLION8}>
                        Initial Deposit: 2 MILLION
                      </p>
                      <p className={Style.MILLION9}>Pre Launch Price Valid</p>
                      <p className={Style.MILLION10}>Till 31st August, 2023</p>
                      <a href="#secure">
                        <button>Secure Now</button>
                      </a>
                    </div>
                  </div>

                  <div className={Style.MILLION2}>
                    <img
                      src={ }
                      alt=" "
                      className={Style.MILLION2Img}
                    />
                    <div className={Style.MILLION4}>
                      <h3>Pre-Launch Price</h3>
                      <p className={Style.MILLION5}>
                         <IoLocationSharp/>
                        Eleko, Idera Scheme, Ibeju Lekki
                      </p>
                      <p className={Style.MILLION6}>Lagos State Allocation</p>
                      <div className={Style.MILLION7}>
                        <h2>13-18 MONTHS</h2>
                        <h2>N19.5 MILLION</h2>
                      </div>
                      <p className={Style.MILLION8}>
                        Initial Deposit: 2 MILLION
                      </p>
                      <p className={Style.MILLION9}>Pre Launch Price Valid</p>
                      <p className={Style.MILLION10}>Till 31st August, 2023</p>
                      <a href="#secure">
                        <button>Secure Now</button>
                      </a>
                    </div>
                  </div> */}
                </div>
              )}

              {num === 1 && (
                <div className={Style.MILLION}>
                  <div className={Style.MILLION2}>
                    <div className={Style.MILLION4}>
                      <h3>0-3 MONTHS</h3>
                      <p className={Style.MILLION5}>
                        {/* <IoLocationSharp /> */}
                        Key into Tiwa Garden City Phase II Pre launch price
                        before its deadline
                      </p>
                      <div className={Style.PreLaunch}>
                        <div>
                          <h4>Pre-Launch Price</h4>
                          <h1>18M</h1>
                        </div>
                      </div>
                      <p>
                        <IoDocumentText /> Government Allocation
                      </p>
                      <p>
                        <IoLocationSharp /> By Eleran Igbe Bus Stop
                      </p>
                      <p>
                        <IoReceiptSharp /> Statutory & infrastructure 3.6M
                      </p>
                      <a href="#secure">
                        <button>Secure Now</button>
                      </a>
                    </div>
                  </div>

                  <div className={Style.MILLION2}>
                    <div className={Style.MILLION4}>
                      <h3>4-6 MONTHS</h3>
                      <p className={Style.MILLION5}>
                        {/* <IoLocationSharp /> */}
                        Key into Tiwa Garden City Phase II Pre launch price
                        before its deadline
                      </p>
                      <div className={Style.PreLaunch}>
                        <div>
                          <h4>Pre-Launch Price</h4>
                          <h1>19M</h1>
                        </div>
                      </div>
                      <p>
                        <IoDocumentText /> Government Allocation
                      </p>
                      <p>
                        <IoLocationSharp /> By Eleran Igbe Bus Stop
                      </p>
                      <p>
                        <IoReceiptSharp /> Statutory & infrastructure 3.6M
                      </p>
                      <a href="#secure">
                        <button>Secure Now</button>
                      </a>
                    </div>
                  </div>

                  <div className={Style.MILLION2}>
                    <div className={Style.MILLION4}>
                      <h3>7-12 MONTH</h3>
                      <p className={Style.MILLION5}>
                        {/* <IoLocationSharp /> */}
                        Key into Tiwa Garden City Phase II Pre launch price
                        before its deadline
                      </p>
                      <div className={Style.PreLaunch}>
                        <div>
                          <h4>Pre-Launch Price</h4>
                          <h1>20M</h1>
                        </div>
                      </div>
                      <p>
                        <IoDocumentText /> Government Allocation
                      </p>
                      <p>
                        <IoLocationSharp /> By Eleran Igbe Bus Stop
                      </p>
                      <p>
                        <IoReceiptSharp /> Statutory & infrastructure 3.6M
                      </p>
                      <a href="#secure">
                        <button>Secure Now</button>
                      </a>
                    </div>
                  </div>

                  <div className={Style.MILLION2}>
                    <div className={Style.MILLION4}>
                      <h3>13-18 MONTH</h3>
                      <p className={Style.MILLION5}>
                        {/* <IoLocationSharp /> */}
                        Key into Tiwa Garden City Phase II Pre launch price
                        before its deadline
                      </p>
                      <div className={Style.PreLaunch}>
                        <div>
                          <h4>Pre-Launch Price</h4>
                          <h1>21M</h1>
                        </div>
                      </div>
                      <p>
                        <IoDocumentText /> Government Allocation
                      </p>
                      <p>
                        <IoLocationSharp /> By Eleran Igbe Bus Stop
                      </p>
                      <p>
                        <IoReceiptSharp /> Statutory & infrastructure 3.6M
                      </p>
                      <a href="#secure">
                        <button>Secure Now</button>
                      </a>
                    </div>
                  </div>

                  {/* <div className={Style.MILLION2}>
                      <img
                        src={ }
                        alt=" "
                        className={Style.MILLION2Img}
                      />
                      <div className={Style.MILLION4}>
                        <h3>Pre-Launch Price</h3>
                        <p className={Style.MILLION5}>
                           <IoLocationSharp/>
                          Eleko, Idera Scheme, Ibeju Lekki
                        </p>
                        <p className={Style.MILLION6}>Lagos State Allocation</p>
                        <div className={Style.MILLION7}>
                          <h2>0-6 MONTHS</h2>
                          <h2>N15 MILLION</h2>
                        </div>
                        <p className={Style.MILLION8}>
                          Initial Deposit: 2 MILLION
                        </p>
                        <p className={Style.MILLION9}>Pre Launch Price Valid</p>
                        <p className={Style.MILLION10}>Till 31st August, 2023</p>
                        <a href="#secure">
                          <button>Secure Now</button>
                        </a>
                      </div>
                    </div>
  
                    <div className={Style.MILLION2}>
                      <img
                        src={ }
                        alt=" "
                        className={Style.MILLION2Img}
                      />
                      <div className={Style.MILLION4}>
                        <h3>Pre-Launch Price</h3>
                        <p className={Style.MILLION5}>
                           <IoLocationSharp/>
                          Eleko, Idera Scheme, Ibeju Lekki
                        </p>
                        <p className={Style.MILLION6}>Lagos State Allocation</p>
                        <div className={Style.MILLION7}>
                          <h2>7-12 MONTHS</h2>
                          <h2>N17 MILLION</h2>
                        </div>
                        <p className={Style.MILLION8}>
                          Initial Deposit: 2 MILLION
                        </p>
                        <p className={Style.MILLION9}>Pre Launch Price Valid</p>
                        <p className={Style.MILLION10}>Till 31st August, 2023</p>
                        <a href="#secure">
                          <button>Secure Now</button>
                        </a>
                      </div>
                    </div>
  
                    <div className={Style.MILLION2}>
                      <img
                        src={ }
                        alt=" "
                        className={Style.MILLION2Img}
                      />
                      <div className={Style.MILLION4}>
                        <h3>Pre-Launch Price</h3>
                        <p className={Style.MILLION5}>
                           <IoLocationSharp/>
                          Eleko, Idera Scheme, Ibeju Lekki
                        </p>
                        <p className={Style.MILLION6}>Lagos State Allocation</p>
                        <div className={Style.MILLION7}>
                          <h2>7-12 MONTHS</h2>
                          <h2>N18 MILLION</h2>
                        </div>
                        <p className={Style.MILLION8}>
                          Initial Deposit: 2 MILLION
                        </p>
                        <p className={Style.MILLION9}>Pre Launch Price Valid</p>
                        <p className={Style.MILLION10}>Till 31st August, 2023</p>
                        <a href="#secure">
                          <button>Secure Now</button>
                        </a>
                      </div>
                    </div>
  
                    <div className={Style.MILLION2}>
                      <img
                        src={ }
                        alt=" "
                        className={Style.MILLION2Img}
                      />
                      <div className={Style.MILLION4}>
                        <h3>Pre-Launch Price</h3>
                        <p className={Style.MILLION5}>
                           <IoLocationSharp/>
                          Eleko, Idera Scheme, Ibeju Lekki
                        </p>
                        <p className={Style.MILLION6}>Lagos State Allocation</p>
                        <div className={Style.MILLION7}>
                          <h2>13-18 MONTHS</h2>
                          <h2>N19.5 MILLION</h2>
                        </div>
                        <p className={Style.MILLION8}>
                          Initial Deposit: 2 MILLION
                        </p>
                        <p className={Style.MILLION9}>Pre Launch Price Valid</p>
                        <p className={Style.MILLION10}>Till 31st August, 2023</p>
                        <a href="#secure">
                          <button>Secure Now</button>
                        </a>
                      </div>
                    </div> */}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className={Style.holdLandmarks}>
        <div className={Style.holdLandmarks2}>
          <h1>Tiwa Garden City II Estate Landmarks</h1>
          <div className={Style.holdLandmarks3}>
            <div></div>
          </div>
          <p>
            Tiwa Garden City II is positioned in the heart of Ibeju Lekki by
            Eleko Junction, sharing boundary with Pan African University and
            many notable landmarks
          </p>
          <div className={Style.holdLandmarksPic}>
            <div className={Style.holdUniversitylandmarl2}>
              <div
                className={Style.holdUniversitylandmarl1}
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <img src={landmarl1} alt="landmarl1" />
                <div className={Style.holdUniversity}>
                  <p>Pan Atlantic University</p>
                </div>
              </div>
              <div className={Style.holdUniversitylandmarl1}>
                <img src={landmarl2} alt="landmarl2" />
                <div className={Style.holdUniversity}>
                  <p>Tiwa Garden Phase 1</p>
                </div>
              </div>
              <div
                className={Style.holdUniversitylandmarl1}
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <img src={landmarl3} alt="landmarl3" />
                <div className={Style.holdUniversity}>
                  <p>Lekki Airport Consortium View</p>
                </div>
              </div>
              <div
                className={Style.holdUniversitylandmarl1}
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <img src={landmarl4} alt="landmarl4" />
                <div className={Style.holdUniversity}>
                  <p>Dangote Refinery</p>
                </div>
              </div>
              <div className={Style.holdUniversitylandmarl1}>
                <img src={landmarl5} alt="landmarl5" />
                <div className={Style.holdUniversity}>
                  <p>Tiwa Commercial Hub</p>
                </div>
              </div>
              <div
                className={Style.holdUniversitylandmarl1}
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <img src={landmarl6} alt="landmarl6" />
                <div className={Style.holdUniversity}>
                  <p>Deep Sea Port</p>
                </div>
              </div>
            </div>
          </div>
          <a href="#secure">
            <button className={Style.suko}>Secure Your Plot</button>
          </a>
        </div>
      </div>

      <div className={Style.Feedback}>
        <div className={Style.Feedback2}>
          <div className={Style.Feedback3}>
            <h1>Client’s Feedback</h1>
            <div className={Style.holdLandmarks3}>
              <div></div>
            </div>
            <p>
              Check out what some of the feedbacks from our latest investors
              after buying land some of our residential and commercial
              properties.
            </p>
          </div>

          <div className={Style.Feedback4}>
            <div>
              <img
                src={test1}
                alt="Not Avaiable"
                className={Style.FeedbackImg}
              />
            </div>
            <div>
              <h5>Our Allocation Feats</h5>
              <p>
                At Zylus Homes, we have done over one thousand land and
                buildings allocations over the past 5 years of our existence.
              </p>
              <p>
                The reviews and feedbacks from our smart investors are
                overwhelming, also with no cases of unsatisfied clients at all.
              </p>
              <h4>
                Reviews <img src={rating} alt="Not Available" />
              </h4>
              <p>
                We have always set a hall mark for great & sophisticated urban
                living and unique and elegant features . Zylus Homes always
                deliver the absolute best, never failing and faltering. It is
                always a pleasure doing business with you.
              </p>
              <p>
                Thank you, Zylus Homes - <span>Mrs Adeola</span>
              </p>
              <p>
                It has been amazing doing business with zylus homes unlike other
                firms that don’t get allocated on time. Zylus has made every
                process seamless from payment to allocation.
              </p>
              <p>
                Thank you Zylus for making my dream come through within 1 month.
              </p>
              <h3>- Adebakin Temidayo</h3>
            </div>
          </div>
          <div className={Style.Feedback5}>
            <img src={test2} alt="Not Available" />
            <img src={test3} alt="Not Available" />
            <img src={test4} alt="Not Available" />
          </div>

          <div>
            <a href="#secure">
              <button className={Style.Feedback6}>Get Allocated Today</button>
            </a>
          </div>
        </div>
      </div>

      <div className={Style.meetNew}>
        <div className={Style.meetNew2}>
          <div className={Style.meetNew3}>
            <h1>Meet The Team</h1>
            <div className={Style.holdLandmarks3}>
              <div></div>
            </div>
            <p>
              Meet The Amazing Team Behind the Sophisticated Tiwa Garden City II
            </p>
          </div>

          <div className={Style.meetNew4s}>
            <div className={Style.meetNew4}>
              <div className={Style.team1}>
                <img src={team1} alt="Not Available" />
                <h5>Mrs Bukola Adeleke</h5>
                <p>ED, Operations/Admin</p>
              </div>
              <div>
                <img src={team2} alt="Not Available" />
                <h5>Dr Oluwatosin Olatujoye</h5>
                <p>GCEO, Zylus Group International</p>
              </div>
              <div className={Style.team1}>
                <img src={team3} alt="Not Available" />
                <h5>Ojo Olugbenga</h5>
                <p>GMD/COO</p>
              </div>
            </div>

            <div className={Style.meetNew5}>
              <div>
                <img src={team4} alt="Not Available" />
                <h5>Lateef Lawal</h5>
                <p>ED, Real Estate</p>
                <p>(Zylus Homes and Properties) </p>
              </div>
              <div>
                <img src={team5} alt="Not Available" />
                <h5>Wasiu Sonekan</h5>
                <p>ED, Finance</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={Style.holdParadise}>
        <div className={Style.holdParadise2}>
          <div className={Style.holdParadise3}>
            <h2>Secure Your Own Piece of Paradise</h2>
            <div className={Style.holdLandmarks3}>
              <div></div>
            </div>
            <p>
              Fill out the form and a member of the team would reach out to you
              and break down your land acquisition process.
            </p>
          </div>

          <div className={Style.holdLifestyle1}>
            <div
              className={Style.holdLifestyle2}
              data-aos="slide-left"
              data-aos-duration="1000"
            >
              <h1 className={Style.weHave2}>
                Tiwa Garden City II -<br />
                <span>Where Luxury Meets Lifestyle</span>{" "}
              </h1>
              <p className={Style.weHave}>
                We have always set a hallmark for sophisticated urban living and
                features unique and elegant designs tailored to meet the needs
                of the modern-day investor as well as appeal to aesthetics.
              </p>
              <div className={Style.holdLifestyle2Pro1}>
                <div className={Style.holdLifestyle2Pro3}>
                  <div className={Style.holdLifestyle2Pro2}>
                    <img src={mail1} alt="energy1" />
                    <p>Gatehouse</p>
                  </div>
                  <div className={Style.holdLifestyle2Pro2}>
                    <img src={mail2} alt="energy1" />
                    <p>Transformer</p>
                  </div>
                  <div className={Style.holdLifestyle2Pro2}>
                    <img src={mail3} alt="energy1" />
                    <p>Estate Mall</p>
                  </div>
                  <div className={Style.holdLifestyle2Pro2}>
                    <img src={mail4} alt="energy1" />
                    <p>Recreational Park</p>
                  </div>
                  <div className={Style.holdLifestyle2Pro2}>
                    <img src={mail5} alt="energy1" />
                    <p>Recreational Park</p>
                  </div>
                  <div className={Style.holdLifestyle2Pro2}>
                    <img src={mail6} alt="energy1" />
                    <p>Recreational Park</p>
                  </div>
                </div>
              </div>
              <a href={FAQS} target="_blank" rel="noreferrer" download>
                <button>Download Form & FAQ</button>
              </a>
            </div>

            <div
              className={Style.holdLifestyle3}
              id="secure"
              data-aos="slide-right"
              data-aos-duration="1000"
            >
              <div className={Style.holdFromPro}>
                <div className={Style.holdFrom2P}>
                  <h1 className={Style.holdFrom2H1}>
                    SECURE YOUR PIECE OF TIWA GARDEN CITY II
                  </h1>
                  <div className={Style.holdRedLine}>
                    <div></div>
                  </div>
                  <form onSubmit={handleSubmit2} className={Style.holdForm3P}>
                    <input
                      type="text"
                      placeholder="Name"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      required
                    />

                    <input
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />

                    <input
                      type="number"
                      placeholder="Phone Number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />

                    <input
                      type="text"
                      placeholder="Country"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      required
                    />

                    <select
                      value={realtor}
                      onChange={(e) => setRealtor(e.target.value)}
                      required
                    >
                      <option value="">What are you interested in ?</option>
                      <option value="500 SQM">500 SQM</option>
                      <option value="300 SQM">300 SQM</option>
                      <option value="Other">Other</option>
                    </select>

                    <select
                      value={purchase}
                      onChange={(e) => setPurchase(e.target.value)}
                      required
                    >
                      <option value="">
                        How many plot do you want to buy ?
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </select>

                    {/* <input
                          type="number"
                          value={purchase}
                          onChange={(e) => setPurchase(e.target.value)}
                          placeholder="How many lot do you want to buy ?"
                          required
                        /> */}

                    <select
                      value={soon}
                      onChange={(e) => setSoon(e.target.value)}
                      required
                    >
                      <option value="">
                        How soon did you want to purchase your land ?
                      </option>
                      <option value="Immediately">Immediately</option>
                      <option value="Within a week">Within a week</option>
                      <option value="Within a month">Within a month</option>
                    </select>

                    <div className={Style.holdCheckBox}>
                      <input type="checkbox" required />
                      <p>
                        I consent to have this website store my submitted
                        information so they can respond to my inquiry.
                      </p>
                    </div>

                    {/* <select
                      value={hear}
                      onChange={(e) => setHear(e.target.value)}
                      required
                    >
                      <option value="">How did you hear about us ?</option>
                      <option value="Facebook">Facebook</option>
                      <option value="Billboard">Billboard</option>
                      <option value="Instagram"> Instagram</option>
                      <option value="Twitter">Twitter</option>
                      <option value="Google">Google</option>
                      <option value="LinkedIn">LinkedIn</option>
                      <option value="Referred by someone">
                        Referred by someone
                      </option>
                    </select> */}

                    <button type="submit">
                      {loading2 ? "Please wait..." : "SUBMIT PURCHASE INTEREST"}
                    </button>
                  </form>
                  {/* <form
                        onSubmit={handleSubmit3}
                        className={Style.holdForm3}
                      >
                        <input
                          type="text"
                          placeholder="Name"
                          value={fullName2}
                          onChange={(e) => setFullName2(e.target.value)}
                          required
                        />

                        <input
                          type="email"
                          placeholder="Email"
                          value={email2}
                          onChange={(e) => setEmail2(e.target.value)}
                          required
                        />

                        <input
                          type="Phone Number"
                          placeholder="Phone Number"
                          value={phone2}
                          onChange={(e) => setPhone2(e.target.value)}
                          required
                        />

                        <select
                          value={build}
                          onChange={(e) => setBuild(e.target.value)}
                          required
                        >
                          <option>
                            What do you intend to build in Arizon ?
                          </option>
                          <option value="Office space">Office space</option>
                          <option value="Shopping complex">
                            Shopping complex
                          </option>
                          <option value="Restaurant">Restaurant</option>
                          <option value="Cinema">Cinema</option>
                          <option value="Supermarket">Supermarket</option>
                          <option value="Warehouse and Logistics">
                            Warehouse and Logistics
                          </option>
                          <option value="Tech hub">Tech hub</option>
                          <option value="Event center">Event center</option>
                          <option value="Game Center">Game Center</option>
                          <option value="Hotels">Hotels</option>
                          <option value="Short-let">Short-let</option>
                          <option value="Wellness center">
                            Wellness center
                          </option>
                          <option value="Others">Others</option>
                        </select>

                        <select
                          value={considering}
                          onChange={(e) => setConsidering(e.target.value)}
                          required
                        >
                          <option>How many plots are you considering ?</option>
                          <option value="300 SQM (N15 MILLION)">
                            300 SQM (N15 MILLION)
                          </option>
                          <option value="500 SQM (N25 MILLION)">
                            500 SQM (N25 MILLION)
                          </option>
                        </select>

                        <input
                          type="text"
                          value={purchase2}
                          onChange={(e) => setPurchase2(e.target.value)}
                          onFocus={(e) => (e.target.type = "date")}
                          onBlur={(e) => (e.target.type = "text")}
                          placeholder="When do you intend to purchase Arizon ?"
                          required
                        />
                        <button type="submit">SUBMIT</button>
                      </form> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={Style.holdAnytime}>
        <div>
          <h1 className={Style.Reach}>You Can Reach Us Anytime</h1>
          <p className={Style.question}>
            Have a question or doubt about our property?
          </p>
        </div>
        <a href="tel:+2347061032706" target="_blank" rel="noopener noreferrer">
          <button className={Style.callUs}>
            <IoCall />
            Call us
          </button>
        </a>
      </div>
      <FooterPhase2 />

      <div className={video2 ? Style.holdModalAll : Style.holdModalAllMG}>
        <div className={Style.holdModalAll2}>
          <div className={Style.holdCeremonyHead}>
            <h1>Tiwa Garden City Phase II</h1>
            <IoCloseSharp onClick={videoButin2} />
          </div>
          <div className={Style.holdCeremonyVideo}>
            <div>
              <iframe
                src={video2 && "https://www.youtube.com/embed/BSnxPm9U8"}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div className={Style.holdClose}>
            <button onClick={videoButin2}>Close</button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home2;
